import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/icon';

import { Translation } from 'react-i18next';

import Modal from 'components/common/modal';
import { DeleteContact } from 'components/common/icons';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

export const ModalDeleteConfirm = ({
  title,
  isLoading,
  onClose,
  iconModal,
  onConfirm,
  description,
  cancelBtnText,
  deleteBtnText,
  ...props
}) => (
  <Modal
    width={440}
    contentStyle={{ padding: '32px 24px', textAlign: 'center' }}
    onClose={onClose}
    {...props}
  >
    <div style={{ marginBottom: 20, padding: '36px 0 0' }}>
      <Icon component={iconModal} />
    </div>

    <Typography.Title
      level={4}
      style={{
        fontSize: 24,
        marginBottom: 20,
        lineHeight: '1.4',
        fontWeight: 700
      }}
    >
      {title}
    </Typography.Title>

    {description && (
      <Typography.Paragraph color="black-45">
        {description}
      </Typography.Paragraph>
    )}

    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 32
      }}
    >
      <Button type="secondary" width="expanded" size="large" onClick={onClose}>
        {cancelBtnText}
      </Button>

      <Button
        type="primary"
        mood="negative"
        size="large"
        width="expanded"
        loading={isLoading}
        onClick={onConfirm}
        style={{ marginLeft: 20 }}
      >
        {deleteBtnText}
      </Button>
    </div>
  </Modal>
);

ModalDeleteConfirm.propsTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  iconModal: PropTypes.func,
  description: PropTypes.string,
  cancelBtnText: PropTypes.string,
  deleteBtnText: PropTypes.string
};

ModalDeleteConfirm.defaultProps = {
  isLoading: false,
  iconModal: DeleteContact,
  description: null,
  cancelBtnText: <Translation ns="Common">{t => t('Cancel')}</Translation>,
  deleteBtnText: <Translation ns="Common">{t => t('Detele')}</Translation>
};

export default ModalDeleteConfirm;
