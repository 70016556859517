import React, { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput } from 'components/common/hook-form';

const InputTypeNumber = forwardRef((props, ref) => {
  const form = useFormContext();

  const { t } = useTranslation([
    'TagsEditReplaceDelete',
    'Errors',
    'TagsInTask'
  ]);

  const containsComma = value => value.includes(',');
  const containsDot = value => value.includes('.');

  const normalizeNumberString = value => value.replace(',', '.');

  const splitIntoGroupsOfThree = (value, separator) =>
    value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

  const getSeparator = (hasComma, hasDot) => {
    if (hasComma) return ',';
    if (hasDot) return '.';
    return '';
  };

  const isSingleComma = value => value === ',';
  const isSingleSpace = value => value === ' ';
  const isSingleDot = value => value === '.';

  const hasOnlyMultipleCommas = value => {
    const commaMatches = value.match(/,/g) || [];
    return commaMatches.length > 1 && /^,+$/.test(value);
  };

  const hasOnlyMultipleSpaces = value => {
    if (/\s/.test(value)) {
      const spaceMatches = value.match(/\s/g) || [];
      return spaceMatches.length > 1 && /^\s+$/.test(value);
    }
    return false;
  };

  const hasNoLeadingOrTrailingSpaces = value => {
    if (/\s/.test(value)) {
      return /^\s|\s$/.test(value);
    }
    return false;
  };

  const formatNumberWithGrouping = value => {
    const numberStr = value.slice().replace(/\s+/g, '');
    const separator = ' ';
    const isComma = containsComma(numberStr);
    const isDot = containsDot(numberStr);

    const normalizedNumberStr = normalizeNumberString(numberStr);
    const [integerPart, decimalPart] = normalizedNumberStr.split('.');

    const formattedIntegerPart = splitIntoGroupsOfThree(integerPart, separator);

    return decimalPart
      ? `${formattedIntegerPart}${getSeparator(isComma, isDot)}${decimalPart}`
      : `${formattedIntegerPart}${getSeparator(isComma, isDot)}`;
  };

  const handleNumberInput = event => {
    const numberValue = event.target.value;

    const validRegex = /^[0-9\s.,]+$/;
    const regexWithGroupingSpace = /^-?(\d{1,3})( \d{3})*(\.|,)?\d*$/;

    if (
      validRegex.test(numberValue) &&
      !hasOnlyMultipleCommas(numberValue) &&
      !isSingleComma(numberValue) &&
      !isSingleSpace(numberValue) &&
      !isSingleDot(numberValue) &&
      !hasOnlyMultipleSpaces(numberValue) &&
      !hasNoLeadingOrTrailingSpaces(numberValue) &&
      regexWithGroupingSpace.test(numberValue)
    ) {
      const formattedValue = formatNumberWithGrouping(numberValue);
      form.setValue('name', formattedValue);
    } else {
      form.setValue('name', numberValue);
    }
  };

  return (
    <FormInput
      name="name"
      ref={ref}
      label={t('TagName', { ns: 'TagsEditReplaceDelete' })}
      rules={{
        required: t('RequiredField', { ns: 'Errors' }),
        maxLength: {
          value: 25,
          message: t('TagMaxLength', {
            ns: 'TagsEditReplaceDelete'
          })
        },
        pattern: {
          value: /^[0-9\s.,]+$/,
          message: t('InvalidCharactersEntered', { ns: 'TagsInTask' })
        }
      }}
      data-testid="number-type-input"
      onChange={handleNumberInput}
    />
  );
});

export default InputTypeNumber;
