import React, { Fragment, createContext, useContext, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Dropdown, Button, Divider, Tooltip, Spin, Menu } from 'antd';
import { Translation, useTranslation } from 'react-i18next';

import PopConfirm from 'components/common/pop-confirm';
import Icon from 'components/common/icon';

import { ReleaseFeatureTag } from '../release';
import { getClearOrderingKey } from './utils';

import styles from './actions.module.scss';

const ActionContent = ({ action }) => (
  <>
    {action.icon && (
      <Icon
        type={action.icon}
        color={(action.style || {}).color ? 'inherit' : 'black-55'}
        data-qa="qa-bo2bkhr7cew78te"
        size={action.iconSize || 24}
      />
    )}

    <span>
      <Translation ns={action.ns}>{t => t(action.title)}</Translation>
    </span>

    {action.featureName && (
      <ReleaseFeatureTag featureName={action.featureName} />
    )}
  </>
);

const ActionButton = ({ action, handleClick }) => (
  <Button
    type="text"
    onClick={event => {
      event.preventDefault();
      event.stopPropagation();

      handleClick(action.key);
    }}
    className={classnames(styles.action, action.className)}
    data-qa="qa-6uz44oys4jmolvq"
    disabled={action.disabled}
    style={action.style}
  >
    <ActionContent action={action} />
  </Button>
);

const ActionsContext = createContext();

const useActionsContext = () => {
  const context = useContext(ActionsContext);

  return context;
};

export const ActionsDropdown = ({
  actions,
  className,
  btnClassName,
  iconClassName,
  overlayClassName,
  trigger,
  getPopupContainer,
  dropdownProps,
  onClick,
  children,
  orderingKey,
  btnStyle,
  isLoading,
  afterSelectCallback,
  afterChangeVisibilityCallback,
  placement,
  shouldStopPropagation
}) => {
  const [visible, setVisible] = useState(false);

  if (!actions || actions.every(action => !action.allow)) {
    return null;
  }

  const content = (
    <div
      className={classnames(styles.content, className)}
      data-qa="qa-nwm73rt1vbvgxf4"
    >
      {actions
        .filter(action => !!action.allow)
        .map((action, index) => {
          const handleClick = key => {
            if (onClick !== undefined) {
              return onClick(key);
            }

            action.onClick(key);
            setVisible(false);
            return afterSelectCallback();
          };

          const allowedChildren = (action.children || []).filter(c => c.allow);

          return (
            <Fragment key={`${action.title}-${index}`}>
              {action.divider && (
                <Divider
                  className={styles.divider}
                  data-qa="qa-oopacde0mh4t0u8"
                />
              )}

              {action.isConfirm && (
                <PopConfirm
                  title={action.confirmText}
                  onConfirm={() => handleClick(action.key)}
                  style={{ maxWidth: 202 }}
                  placement={action.placement}
                >
                  <Button
                    type="text"
                    className={classnames(styles.action, action.className)}
                    data-qa="qa-6uz44oys4jmolvq"
                    disabled={action.disabled}
                    style={action.style}
                  >
                    <ActionContent action={action} />
                  </Button>
                </PopConfirm>
              )}

              {!action.isConfirm && !(action.children || []).length && (
                <ActionButton action={action} handleClick={handleClick} />
              )}

              {!action.isConfirm && !!allowedChildren.length && (
                <Menu selectable={false}>
                  <Menu.SubMenu
                    title={<ActionContent action={action} />}
                    popupClassName={styles.submenuPopup}
                  >
                    {allowedChildren.map(child => (
                      <Menu.Item key={child.title}>
                        <ActionButton
                          action={child}
                          handleClick={() => {
                            child.onClick();

                            setVisible(false);
                          }}
                        />
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                </Menu>
              )}
            </Fragment>
          );
        })}
    </div>
  );

  const handleVisibility = value => {
    setVisible(value);
    afterChangeVisibilityCallback(value);
  };

  return (
    <ActionsContext.Provider value={{ orderingKey, onClick, ...dropdownProps }}>
      <Dropdown
        overlay={content}
        trigger={[trigger]}
        overlayClassName={classnames(styles.overlayClassName, overlayClassName)}
        placement={placement}
        getPopupContainer={getPopupContainer}
        onOpenChange={handleVisibility}
        open={visible}
        {...dropdownProps}
      >
        {children ? (
          <div
            className={btnClassName}
            style={btnStyle}
            data-qa="qa-osr3sd76p2k23rb"
            onClick={e => {
              if (shouldStopPropagation) e.stopPropagation();
            }}
          >
            {children}
          </div>
        ) : (
          <Button
            size="small"
            type="default"
            className={classnames(styles.btn, btnClassName)}
            style={btnStyle}
            data-qa="qa-km8pfm9dgh03pkf"
          >
            <Spin spinning={isLoading} size="small">
              <Icon
                type="more"
                color="black-55"
                side="left"
                size={20}
                className={classnames(iconClassName)}
                data-qa="qa-bp2fxepi14vd2h1"
              />
            </Spin>
          </Button>
        )}
      </Dropdown>
    </ActionsContext.Provider>
  );
};

const Sort = ({ ...props }) => {
  const { orderingKey, onClick } = useActionsContext();

  const { t } = useTranslation('Common');

  const sort = orderingKey.startsWith('-') ? '-' : '';

  const handleChangeSort = () => {
    const resultOrderingKey = getClearOrderingKey(orderingKey);

    const resultSort = sort ? '' : '-';

    onClick(resultOrderingKey, resultSort);
  };

  return (
    <Tooltip title={t(sort ? 'Descending' : 'Ascending')} mouseEnterDelay={0.5}>
      <Button
        type="text"
        className={styles.btnSort}
        data-qa="qa-tiu0pbr9u2fdbrb"
        onClick={e => {
          e.stopPropagation();
          handleChangeSort();
        }}
        {...props}
      >
        <Icon
          type={sort ? 'sort-descending' : 'sort-ascending'}
          size={18}
          style={{ lineHeight: 0 }}
        />
      </Button>
    </Tooltip>
  );
};

ActionsDropdown.Sort = Sort;

ActionsDropdown.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  trigger: PropTypes.oneOf(['hover', 'click', 'contextMenu']),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      allow: PropTypes.bool,
      icon: PropTypes.any,
      className: PropTypes.string,
      iconSize: PropTypes.number
    }).isRequired
  ).isRequired,
  getPopupContainer: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  orderingKey: PropTypes.string,
  isLoading: PropTypes.bool,
  afterSelectCallback: PropTypes.func,
  placement: PropTypes.oneOf([
    'bottomLeft',
    'bottomCenter',
    'bottomRight',
    'topLeft',
    'topCenter',
    'topRight'
  ]),
  afterChangeVisibilityCallback: PropTypes.func,
  shouldStopPropagation: PropTypes.bool
};

ActionsDropdown.defaultProps = {
  className: undefined,
  overlayClassName: undefined,
  iconClassName: undefined,
  trigger: 'click',
  getPopupContainer: trigger => trigger.parentNode,
  onClick: undefined,
  children: undefined,
  orderingKey: '',
  isLoading: false,
  afterSelectCallback: () => {},
  placement: 'bottomRight',
  afterChangeVisibilityCallback: () => {},
  shouldStopPropagation: false
};

export default ActionsDropdown;
