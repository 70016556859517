import React, { useState } from 'react';
import { Checkbox, Input, Typography } from 'antd';
import { Form } from '@ant-design/compatible';

import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  RESPONSIBLE,
  TITLE,
  TYPE_TASK,
  TEMPLATES,
  BACKLOG
} from 'constants/index';

import {
  EmployeeSelect,
  ProjectParticipantsSelect
} from 'components/common/controls/custom-select';
import Modal from 'components/common/modal';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import { TemplateView } from 'components/tasks-view/template-view';
import Button from 'components/common/button';

import { createTask } from 'store/tasks';

import getSubtaskValue from 'utils/get-subtask-value';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';

import styles from './styles.module.scss';

const { Title } = Typography;

export const getKindSubTask = ({ kind, projectId, status }) => {
  if (status === BACKLOG) {
    return TYPE_TASK;
  }

  return projectId && kind === TYPE_TASK ? TYPE_TASK : null;
};

const CreatorForm = Form.create()(
  ({ form, task, onCallback, onShowFullForm }) => {
    const dispatch = useDispatch();

    const amplitude = useAmplitude();

    const [isLoading, setIsLoading] = useState(false);
    const [copyBtnVisible, setCopyBtnVisible] = useState(true);

    const { t } = useTranslation(['AddSubtask', 'Errors']);

    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

    const isFromTemplate = getFieldValue(TEMPLATES);

    const getInitialResponsible = () => ({
      value: task.responsible.id,
      label: task.responsible
    });

    const getDateStart = dateStart =>
      dateStart && moment(dateStart).isAfter(moment())
        ? moment(dateStart).toDate()
        : undefined;

    const onCreate = event => {
      event.preventDefault();

      form.validateFields(async (err, values) => {
        if (!err) {
          setIsLoading(true);

          const data = getSubtaskValue({
            subtask: values,
            task: { ...task, kind: TYPE_TASK },
            isSubTask: true
          });

          try {
            await dispatch(
              createTask({
                task: data
              })
            );

            amplitude.addTaskEvent({
              value: data
            });

            onCallback();
          } finally {
            setIsLoading(false);
          }
        }
      });
    };

    const onCreateFromTemplate = template => {
      const initialData = {
        ...template,
        parent: task.id,
        dateStart: getDateStart(task.dateStart),
        maxDateEnd: task.dateEnd,
        projectId: (task.project || {}).id,
        kind: template.kind,
        isFromTemplate: true
      };
      onShowFullForm(initialData);
      onCallback();
    };

    const onOpenFullForm = () => {
      const initialData = {
        parent: task.id,
        title: getFieldValue(TITLE),
        dateStart: getDateStart(task.dateStart),
        maxDateEnd: task.dateEnd,
        responsible: [
          (getFieldValue(RESPONSIBLE) || {}).label || task.responsible
        ],
        project: task.project,
        kind: getKindSubTask(task),
        isFromTemplate: task.status === BACKLOG
      };
      onShowFullForm(initialData);
      onCallback();
    };

    const setTitle = () => {
      setFieldsValue({ [TITLE]: task.title });
      setCopyBtnVisible(false);
    };

    const Select = (task.project || {}).id
      ? ProjectParticipantsSelect
      : EmployeeSelect;

    return (
      <Form
        colon={false}
        hideRequiredMark
        style={{ display: 'flex', flexDirection: 'column', padding: 20 }}
        data-qa="qa-zjjlq387zowt2gj"
      >
        <Form.Item>
          {getFieldDecorator(TEMPLATES, {
            initialValue: false,
            valuePropName: 'checked'
          })(<Checkbox>{t('ChooseFromTemplatesChckbx')}</Checkbox>)}
        </Form.Item>

        {isFromTemplate && (
          <TemplateView
            onSubmit={onCreateFromTemplate}
            drawerVisible={isFromTemplate}
            onlyTaskKindAllowed={task.status === BACKLOG}
          />
        )}

        {!isFromTemplate && (
          <>
            <Form.Item
              label={t('SubtaskName')}
              style={{ marginBottom: copyBtnVisible ? 4 : 16 }}
            >
              {getFieldDecorator(TITLE, {
                rules: [
                  {
                    required: true,
                    message: t('RequiredField', { ns: 'Errors' })
                  }
                ]
              })(
                <Input
                  autoFocus
                  placeholder={t('SubtaskNamePlaceholder')}
                  autoComplete="off"
                />
              )}
            </Form.Item>

            {copyBtnVisible && (
              <Button
                type="link"
                style={{
                  padding: 0,
                  fontSize: 12,
                  fontWeight: 400,
                  height: 'auto',
                  alignSelf: 'flex-start',
                  marginBottom: 16
                }}
                onClick={setTitle}
              >
                {t('CopyFromMainTaskBtn')}
              </Button>
            )}

            <Form.Item label={t('TaskResponsible')}>
              {getFieldDecorator(RESPONSIBLE, {
                initialValue: getInitialResponsible(),
                rules: [
                  {
                    required: true,
                    message: t('RequiredField', { ns: 'Errors' })
                  }
                ]
              })(
                <Select
                  projectId={(task.project || {}).id}
                  data-qa="qa-agw5c5wbm4hhvdr"
                  hideWorkspace
                  params={{ excludeBench: false }}
                />
              )}
            </Form.Item>
          </>
        )}

        <div className={styles.btnsWrapper} data-qa="qa-4ieo0a1gqbdx169">
          <Button
            type="link"
            size="large"
            style={{ paddingLeft: 0 }}
            onClick={onOpenFullForm}
          >
            {t('OpenFullTaskFormBtn')}
          </Button>

          {!isFromTemplate && (
            <Button
              type="primary"
              loading={isLoading}
              style={{ fontSize: 16 }}
              onClick={onCreate}
              data-qa="qa-mb8gqu9w8472dlo"
            >
              {t('SaveBtn')}
            </Button>
          )}
        </div>
      </Form>
    );
  }
);

CreatorForm.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    assignments: PropTypes.array
  }),
  onCallback: PropTypes.func
};

CreatorForm.defaultProps = {
  task: undefined,
  onCallback: () => {}
};

export const CreateSubtaskModal = ({ onClose, task, ...props }) => {
  const [visibleFullForm, setVisibleFullForm] = useState();

  const { t } = useTranslation('AddSubtask');

  const fullFormInitialPage = (visibleFullForm || {}).isFromTemplate ? 1 : 0;

  return (
    <>
      <Modal
        width={610}
        title={`${t('AddSubtaskHeading')} ${task.id}`}
        onClose={onClose}
        destroyOnClose
        {...props}
      >
        <CreatorForm
          task={task}
          onCallback={onClose}
          onShowFullForm={setVisibleFullForm}
        />
      </Modal>

      <TaskCreatorDrawer
        title={
          <Title level={4} style={{ marginBottom: 0, lineHeight: '24px' }}>
            {t('AddSubtaskHeading')} {task.id}
          </Title>
        }
        visible={visibleFullForm !== undefined}
        value={visibleFullForm}
        onClose={() => setVisibleFullForm(undefined)}
        initialPage={fullFormInitialPage}
      />
    </>
  );
};

CreateSubtaskModal.propTypes = {
  onClose: PropTypes.func
};

CreateSubtaskModal.defaultProps = {
  onClose: () => {}
};

export default CreateSubtaskModal;
