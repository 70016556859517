import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Layout } from 'antd';
import Icon from 'components/common/icon';

import { useTranslation } from 'react-i18next';

import { LogoIcon } from 'components/common/icons';
import { LanguageSelect } from 'components/common/controls/custom-select';

import { getIsSuccess, getUser, updateUser } from 'store/user';
import { getUILanguage, setUILanguage } from 'store/ui';

import styles from './layout-auth.module.scss';

const LayoutAuthContext = createContext({});

export const LayoutAuth = ({ className, isSelectWorkspace, children }) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const isSuccess = useSelector(getIsSuccess);
  const language = useSelector(getUILanguage);

  const [footerContent, setFooterContent] = useState(null);

  const { i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng.value);
    dispatch(setUILanguage(lng.value));

    if (user.token) {
      dispatch(updateUser({ user: { id: user.id, language: lng.value } }));
    }
  };

  return (
    <Layout className={classnames(styles.root, className)}>
      <Icon component={LogoIcon} className={styles.logo} />
      <div
        className={classnames(styles.content, {
          [styles.selectWorkspace]: isSelectWorkspace,
          [styles.notDisplayed]: isSuccess,
          [styles.withFooter]: !!footerContent
        })}
      >
        <LayoutAuthContext.Provider value={{ setFooterContent }}>
          {children}
        </LayoutAuthContext.Provider>
      </div>

      {footerContent}

      <div className={styles.languageSelectWrap}>
        <LanguageSelect
          value={language}
          rootClassName={styles.languageSelect}
          onChange={changeLanguage}
        />
      </div>
    </Layout>
  );
};

LayoutAuth.propTypes = {
  className: PropTypes.string,
  isSelectWorkspace: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

LayoutAuth.defaultProps = {
  className: undefined,
  isSelectWorkspace: false,
  children: undefined
};

export const LayoutAuthFooter = ({ children }) => {
  const { setFooterContent = () => {} } = useContext(LayoutAuthContext);

  useEffect(() => {
    setFooterContent(children);

    return () => {
      setFooterContent(null);
    };
  }, [children, setFooterContent]);

  return null;
};

LayoutAuth.Footer = LayoutAuthFooter;

export default LayoutAuth;
