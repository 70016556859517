import { combineActions } from 'redux-actions';
import { uniqBy, sortBy, isEmpty } from 'lodash';

import {
  fetchChat,
  sendReadAck,
  fetchMessages,
  editMessage,
  setMessageWithEvent,
  clearCommands,
  sendMessage,
  setOpenChat
} from './actions';
import handleActions from '../../utils/redux-actions';
import { NEW_CHAT_MESSAGE, CHAT_MESSAGE_READ } from '../../constants';

const isFormMyWorkspace = workspaceId => {
  const currentWorkspaceId = +localStorage.getItem('workspace');

  // Если workspaceId-ы совпадают, значит сообщение мое
  return workspaceId === currentWorkspaceId;
};

const makeRoleByOrderStatus = orderStatus => (employeeId, role) =>
  (orderStatus.order[role] || {}).id === employeeId;

const getUserEmployeeId = workspace =>
  workspace.entities[workspace.activeId].user.id;

const isSetCounterpartMessageRecent = (orderId, employeeId, store) => {
  const orderStatuses = store.orderStatuses.entries;
  const orderStatus = orderStatuses.find(status => status.order.id === orderId);

  const userEmployeeId = getUserEmployeeId(store.workspace);
  const isCurrentUser = userEmployeeId === employeeId;

  if (!orderStatus || isCurrentUser) {
    return false;
  }

  const isOfflineOrder = orderStatus.order.isOffline;
  const getRoleByKey = makeRoleByOrderStatus(orderStatus);

  const isCurrentController = getRoleByKey(userEmployeeId, 'controller');
  const isSenderController = getRoleByKey(employeeId, 'controller');

  const isCurrentContractor = getRoleByKey(userEmployeeId, 'contractor');
  const isCurrentCustomer = getRoleByKey(userEmployeeId, 'customer');

  const isSendderContractor = getRoleByKey(employeeId, 'contractor');
  const isSenderCustomer = getRoleByKey(employeeId, 'customer');

  const isCurrentAssignee = isCurrentContractor || isCurrentCustomer;
  const isSenderAssignee = isSendderContractor || isSenderCustomer;

  return (
    !(isCurrentController || isCurrentAssignee) ||
    (isOfflineOrder && isCurrentController && isSenderAssignee) ||
    (isOfflineOrder && isCurrentAssignee && isSenderController)
  );
};

const initialState = {
  id: undefined,
  order: null,

  isOpened: false,
  isLoaded: false,
  isLoading: false,
  isMessagesLoading: false,
  isMessagesReadLoading: false,
  isMessagesLoaded: false,

  messageNewCount: 0,
  totalNewResponses: 0,

  messageRecent: null, // Последнее непрочитанное сообщение
  hasMore: true,
  withEvent: null,
  error: null,

  messages: []
};

export default handleActions(
  {
    [fetchChat.START]: (state, { args: { chatId } }) => {
      state.id = chatId;
      state.isLoading = true;

      return state;
    },

    [fetchChat.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchChat.SUCCEEDED]: (state, { payload }) => {
      state.hasMore = true; // TODO: replace true, add condition
      state.isLoaded = true;

      state.messageRecent = payload.messageRecentId;
      state.messageNewCount = payload.messageNewCount;

      Object.keys(payload.chat).forEach(key => {
        state[key] = payload.chat[key];
      });

      return state;
    },

    [sendReadAck.START]: state => {
      state.isMessagesReadLoading = true;

      return state;
    },

    [sendReadAck.ENDED]: state => {
      state.isMessagesReadLoading = false;

      return state;
    },

    [sendMessage.START]: (state, { args }) => {
      const { messages } = state;
      const { message } = args;

      state.messages = [
        ...messages,
        {
          ...message,
          createdAt: undefined,
          id: message.tag
        }
      ];

      return state;
    },

    [sendMessage.SUCCEEDED]: (state, { payload, args }) => {
      state.messages = state.messages.map(message => {
        if (message.id === args.message.tag) {
          return payload;
        }

        return message;
      });

      return state;
    },

    [sendMessage.FAILED]: (state, { payload, args }) => {
      state.messages = state.messages.map(message => {
        if (message.id === args.message.tag) {
          return { ...message, error: payload };
        }

        return message;
      });

      return state;
    },

    [NEW_CHAT_MESSAGE]: (state, { payload }) => {
      if (isFormMyWorkspace(payload.workspaceId) && state.isOpened) {
        // Последнее прочитанное сообщение
        state.messageRecent = payload.id;
      } else {
        state.messageNewCount += 1;
      }

      // При отправке сообщения, мы гинерируем хэш, проверяем на него (tag)
      // и на id, так как он уже мог замениться в store
      const hasMessage = state.messages.find(
        message => message.id === payload.tag || message.id === payload.id
      );

      if (!hasMessage) {
        state.messages.push(payload);
      }

      if (!isEmpty((hasMessage || {}).fileList)) {
        hasMessage.fileList = payload.fileList;
      }

      return state;
    },

    [CHAT_MESSAGE_READ]: (state, { payload, store }) => {
      if (
        // Если не из моего workspace
        !isFormMyWorkspace(payload.workspaceId) ||
        // Если офлайн заказ и один из участниов с обеих сторон
        isSetCounterpartMessageRecent(
          payload.orderId,
          payload.employeeId,
          store
        )
      ) {
        // Последнее прочитанное сообщение собеседником
        state.counterpartMessageRecentId = payload.messageId;

        return state;
      }

      state.messageNewCount = state.messages.filter(
        m => m.id > payload.messageId
      ).length;
      // Последнее прочитанное сообщение
      state.messageRecent = payload.messageId;

      Object.keys(payload).forEach(key => {
        state[key] = payload[key];
      });

      return state;
    },

    [fetchMessages.START]: state => {
      state.isMessagesLoading = true;

      return state;
    },

    [fetchMessages.ENDED]: state => {
      state.isMessagesLoading = false;
      state.isMessagesLoaded = true;

      return state;
    },

    [fetchMessages.SUCCEEDED]: (state, { payload: { results, count } }) => {
      const _messages = uniqBy([...state.messages, ...results], m => m.id);
      const messages = sortBy(_messages, m => m.id);

      state.messages = messages;
      state.hasMore = count > messages.length;

      return state;
    },

    [fetchMessages.FAILED]: (state, { payload }) => {
      state.error = payload;
      state.hasMore = false;

      return state;
    },

    [setMessageWithEvent]: (state, { payload }) => {
      state.withEvent = payload.withEvent;
      state.messages = [];
      state.hasMore = true;

      return state;
    },

    [setOpenChat]: state => {
      state.isOpened = true;

      return state;
    },

    // Пока что неактуально
    [editMessage.SUCCEEDED]: (state, { payload }) => {
      const index = state.messages.findIndex(m => m.id === payload.id);
      state.messages[index] = payload;

      return state;
    },

    [combineActions(fetchChat.FAILED, fetchMessages.FAILED)]: (
      state,
      { payload }
    ) => {
      state.error = payload;

      return state;
    },

    // Установление последней команды в чате при отправке ответа на данные команды
    [clearCommands]: (state, { payload }) => {
      const requestMessagesEvent = state.messages.filter(
        ({ event }) => event && event.isRequest
      );
      const lastRequestEventMessage =
        requestMessagesEvent[requestMessagesEvent.length - 1];

      if (lastRequestEventMessage) {
        const index = state.messages.findIndex(
          ({ id }) => id === lastRequestEventMessage.id
        );

        state.messages[index].event.isAccepted = payload.isAccepted;
      }

      return state;
    }
  },
  initialState
);
