import { createSelector } from 'reselect';
import { memoize } from 'lodash';

export const _getContacts = state => state.contacts;

export const getContacts = createSelector(
  _getContacts,
  contacts => contacts.entries
);

export const getContactFactory = createSelector(getContacts, entries =>
  memoize(id => entries.find(contact => contact.id === id))
);

export const getContactsIsLoading = createSelector(
  _getContacts,
  contacts => contacts.isLoading
);

export const getContactsIsLoaded = createSelector(
  _getContacts,
  contacts => contacts.isLoaded
);

export const getContactsPageData = createSelector(
  _getContacts,
  contacts => contacts.pageData
);

export const getFilterContacts = createSelector(
  _getContacts,
  contacts => contacts.filter
);

export const getContactsSearchFilter = createSelector(
  getFilterContacts,
  filter => filter.search
);

export const getContactsIsOfflineFilter = createSelector(
  getFilterContacts,
  filter => filter.isOffline
);

export const getContactsIsIndividualFilter = createSelector(
  getFilterContacts,
  filter => filter.isIndividual
);

export const getContactsStatusFilter = createSelector(
  getFilterContacts,
  filter => filter.status
);

export const getContactsCreatedDateRangeFilter = createSelector(
  getFilterContacts,
  filter => filter.createdDateRange
);

export const getContactHistory = createSelector(getContacts, contacts =>
  memoize(id => {
    const contact = contacts.find(item => item.id === id);
    return (contact || {}).history || [];
  })
);

export const getCollapsedContactPanel = createSelector(
  _getContacts,
  contacts => contacts.collapsedContactPanel
);
export const getCollapsedFilterPanel = createSelector(
  _getContacts,
  contacts => contacts.collapsedFilterPanel
);

export const getStatusDuplicate = createSelector(
  _getContacts,
  contacts => contacts.statusDuplicate
);
