import { createSelector } from 'reselect';
import { memoize } from 'lodash';

export const getGuides = state => state.guides;

export const getGuidesEntries = createSelector(
  getGuides,
  guides => guides.entries
);

export const getGuidesIsLoading = createSelector(
  getGuides,
  guides => guides.isLoading
);

export const getGuidesIsLoaded = createSelector(
  getGuides,
  guides => guides.isLoaded
);

export const getGuidesCurrentPage = createSelector(
  getGuides,
  guides => guides.currentPage
);

export const getGuidesTotalItems = createSelector(
  getGuides,
  guides => guides.totalItems
);

export const getGuidesItemsPerPage = createSelector(
  getGuides,
  guides => guides.itemsPerPage
);

export const getGuidesFilter = createSelector(
  getGuides,
  guides => guides.filter
);

export const getGuideFactory = createSelector(getGuides, entries =>
  memoize(slug =>
    entries.reduce((acc, curr) => {
      const guide = curr.subcategories.find(sub => sub.slug === slug);

      if (guide) {
        return guide;
      }

      return acc;
    }, undefined)
  )
);

export const getVideos = createSelector(getGuides, guides => guides.videos);

export const getVideoFactory = createSelector(getVideos, videos =>
  memoize(slug => videos.find(video => slug === video.slug))
);
