import React, { useState, Children, cloneElement } from 'react';
import { Tabs } from 'antd';
import { union, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  TYPE_TASK,
  TYPE_ORDER,
  TYPE_CONTACT,
  TYPE_ASSET,
  CLICK_ATTACH_FILE_EVENT,
  CLICK_CREATE_FILE_EVENT,
  CLICK_ADD_FILE_EVENT
} from 'constants/index';

import { getFile } from 'components/attachments-view/drawers/add-file';
import { useViewFile } from 'components/attachments-view/hooks';
import { getEntityData } from 'components/attachments-view/utils';

import useAmplitude from 'hooks/amplitude/use-amplitude';

import Upload from './shared/upload';
import Table from './shared/table';
import Modal from '../modal';
import Create from './shared/create';

import styles from './attachments-control.module.scss';

const { TabPane } = Tabs;

const UPLOAD_TAB = 'upload-tab';
const TABLE_TAB = 'table-tab';

export const ATTACH = 'attach';
export const CREATE = 'create';

const tabs = [
  {
    key: TABLE_TAB,
    title: 'MyDriveTab',
    ns: 'UploadFile',
    Component: Table
  },
  {
    key: UPLOAD_TAB,
    title: 'UploadTab',
    ns: 'UploadFile',
    Component: Upload
  }
];

export const AttachmentsControl = ({
  children,
  disabled,
  fileList,
  uploadFileServer,
  onChange,
  multiple,
  createData,
  actionsDeps,
  entityType: entityTypeProp,
  maxSizeFile,
  maxSizeFileList,
  attachmentProps
}) => {
  const dispatch = useDispatch();
  const { viewFile } = useViewFile();
  const amplitude = useAmplitude();

  const { t } = useTranslation(['CreateFile', 'UploadFile']);

  const [activeTab, setActiveTab] = useState(tabs[0].key);
  const [visible, setVisible] = useState(undefined);

  const onClose = () => setVisible(undefined);

  const onAttach = newFiles => {
    const files = union(
      [
        ...(multiple
          ? fileList.map(f => ({ ...f, isCurrentlyAttach: false }))
          : []),
        ...(newFiles || []).map(file => {
          file.percent = uploadFileServer ? file.percent : 100;
          file.isCurrentlyAttach = true;
          file.validityDateState = {
            isValidDate: true,
            validityDateValue: file.validityDate
          };

          if (attachmentProps && attachmentProps.allowManageSubscribers) {
            file.subscribers = [];
            file.allowSubscriberSelection = false;
          } else {
            file.isNewAttachmentSubscribed = false;
          }

          return file;
        })
      ],
      'uid'
    );

    onChange(uniqBy(files, i => i.id || i.uid));

    // При загрузке файлов, постоянно вызывается метод onChange,
    // что приводит к закрытию модалки
    if (files.length > fileList.length) {
      onClose();
    }
  };

  const onSubmit = async ({ fileList: files, relation }) => {
    const { relationId, relationType, onlineChat } = relation;

    const file = await getFile({
      relationId,
      relationType,
      fileList: files,
      onlineChat,
      dispatch
    });

    onClose();

    if (file) {
      viewFile(file);
    }
  };

  const activeTabs = tabs.filter(({ key }) =>
    !uploadFileServer ? key === UPLOAD_TAB : true
  );

  const onClick = type => {
    if (disabled) {
      return null;
    }

    const { entityType } = getEntityData(actionsDeps);

    if (
      entityType &&
      actionsDeps.sendCopyToComment &&
      (!type || type === ATTACH)
    ) {
      amplitude.logEvent({
        event: CLICK_ATTACH_FILE_EVENT,
        params: { module_system: entityType }
      });
    }

    if (entityType && actionsDeps.sendCopyToComment && type === CREATE) {
      amplitude.logEvent({
        event: CLICK_CREATE_FILE_EVENT,
        params: { module_system: entityType }
      });
    }

    if (
      (entityTypeProp || entityType) &&
      !actionsDeps.sendCopyToComment &&
      (!type || type === ATTACH)
    ) {
      amplitude.logEvent({
        event: CLICK_ADD_FILE_EVENT,
        params: { module_system: entityTypeProp || entityType }
      });
    }

    return setVisible(type || ATTACH);
  };

  return (
    <>
      {Children.map(children, child =>
        cloneElement(child, {
          ...child.props,
          onClick: () => onClick(child.props.modalType)
        })
      )}

      <Modal
        centered
        destroyOnClose
        width={activeTab !== TABLE_TAB ? 884 : 1170}
        visible={visible === ATTACH}
        onClose={onClose}
        contentClassName={styles.content}
      >
        <Tabs
          defaultActiveKey={activeTab}
          onChange={setActiveTab}
          className={styles.tabs}
          tabBarStyle={{ padding: '0 16px' }}
        >
          {activeTabs.map(tab => (
            <TabPane
              tab={t(tab.title, { ns: tab.ns })}
              key={tab.key}
              style={
                tab.key !== TABLE_TAB
                  ? { padding: '0 16px' }
                  : { padding: '0 16px 0 0' }
              }
            >
              <tab.Component
                key={activeTab}
                maxSizeFile={maxSizeFile}
                maxSizeFileList={maxSizeFileList}
                fileList={fileList}
                onChange={onAttach}
                uploadFileServer={uploadFileServer}
                multiple={multiple}
                actionsDeps={actionsDeps}
                onCloseAttachmentModal={onClose}
                attachmentProps={attachmentProps}
              />
            </TabPane>
          ))}
        </Tabs>
      </Modal>

      <Modal
        width={900}
        title={t('CreateFileHeading')}
        visible={visible === CREATE}
        contentClassName={styles.createModalContent}
        onClose={onClose}
        centered
      >
        <Create defaultValues={createData} onSubmit={onSubmit} />
      </Modal>
    </>
  );
};

AttachmentsControl.propTypes = {
  children: PropTypes.any,
  fileList: PropTypes.array,
  disabled: PropTypes.bool,
  uploadFileServer: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  createData: PropTypes.shape({
    title: PropTypes.string,
    relation: PropTypes.shape({
      relationId: PropTypes.number,
      relationType: PropTypes.oneOf([
        TYPE_TASK,
        TYPE_ORDER,
        TYPE_CONTACT,
        TYPE_ASSET
      ])
    })
  }),
  actionsDeps: PropTypes.object
};

AttachmentsControl.defaultProps = {
  children: undefined,
  fileList: [],
  disabled: false,
  uploadFileServer: true,
  multiple: true,
  actionsDeps: {}
};

export default AttachmentsControl;
