import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Tooltip } from 'antd';
import { Form } from '@ant-design/compatible';

import classnames from 'classnames';
import { useTranslation, Translation } from 'react-i18next';

import {
  TITLE,
  EDIT_PROJECT_EVENT,
  ADD_PROJECT_EVENT,
  TYPE_TAGS
} from 'constants/index';

import { EmployeeSelect } from 'components/common/controls/custom-select';
import { Icon } from 'components/common/icon';
import TagsSelect from 'components/common/controls/custom-select/tags-select';
import MarkdownFormItem from 'components/common/new-editor/form/markdown-form-item';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { validateIsRequire } from 'utils/validators';

import styles from './styles.module.scss';

export const CreatorForm = Form.create()(
  ({ form, isLoading, btnLabel, className, values, onSubmit, isEdit }) => {
    const { getFieldDecorator, validateFields } = form;
    const amplitude = useAmplitude();

    const { t } = useTranslation([
      'AddEditProject',
      'Errors',
      'Filters',
      'Projects'
    ]);

    const handleSubmit = event => {
      event.preventDefault();
      event.stopPropagation();

      validateFields((err, valueFields) => {
        if (!err) {
          const eventName = isEdit ? EDIT_PROJECT_EVENT : ADD_PROJECT_EVENT;
          amplitude.logEvent({ event: eventName });
          onSubmit(valueFields);
        }
      });
    };

    return (
      <Form
        className={classnames(styles.root, className)}
        data-qa="qa-h7yadv63r7hmun2"
        hideRequiredMark
        onSubmit={handleSubmit}
        colon={false}
      >
        <div className={styles.infoWrap} data-qa="qa-e3ooe1o3ibbh8ey">
          <Form.Item
            label={
              <span>
                {t('ProjectName')}{' '}
                <Tooltip title={t('ProjectNameLengthRange', { ns: 'Errors' })}>
                  <Icon type="question-circle" size={12} />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator(TITLE, {
              initialValue: values.title,
              rules: [t('ProjectName')]
            })(
              <Input
                allowClear
                autoFocus
                autoComplete="off"
                placeholder={t('EnterProjectName')}
                data-qa="qa-3p5rkngkv7qt0rg"
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <span>
                {t('ProjectDesc')}{' '}
                <Tooltip title={t('ProjectDescLengthRange', { ns: 'Errors' })}>
                  <Icon type="question-circle" size={12} />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('description', {
              initialValue: values.description,
              rules: [validateIsRequire(t('ProjectDesc'))]
            })(
              <MarkdownFormItem
                name="description"
                markdownProps={{
                  placeholder: t('EnterProjectDesc'),
                  showItems: {
                    upload: false,
                    mention: false,
                    emoji: true,
                    topToolbar: true
                  }
                }}
                getFieldDecorator={getFieldDecorator}
                initialValue={{
                  description: values.description || ''
                }}
              />
            )}
          </Form.Item>

          <Form.Item label={t('ProjectManagers', { ns: 'Projects' })}>
            {getFieldDecorator('managers', {
              initialValue: values.managers,
              rules: [validateIsRequire(t('ProjectManager'))]
            })(<EmployeeSelect isMulti />)}
          </Form.Item>

          <Form.Item label={t('Members')}>
            {getFieldDecorator('members', {
              initialValue: values.members,
              rules: [validateIsRequire()]
            })(
              <EmployeeSelect
                placeholder={t('SelectMembers', { ns: 'Filters' })}
                data-qa="qa-sk7qgscnffxh17w"
                isMulti
                allowSelectAll
              />
            )}
          </Form.Item>

          <Form.Item label={t('Tags')}>
            {getFieldDecorator(TYPE_TAGS, {
              initialValue: values.initialTags
            })(<TagsSelect />)}
          </Form.Item>
        </div>

        <Button
          htmlType="submit"
          type="primary"
          loading={isLoading}
          className={styles.submit}
          data-qa="qa-w52r52ogycbtgqw"
        >
          {btnLabel}
        </Button>
      </Form>
    );
  }
);

CreatorForm.propTypes = {
  values: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.array,
    manager: PropTypes.object,
    members: PropTypes.array
  }),
  isLoading: PropTypes.bool,
  btnLabel: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  isEdit: PropTypes.bool
};

CreatorForm.defaultProps = {
  values: {},
  isLoading: false,
  btnLabel: (
    <Translation ns="AddEditProject">{t => t('CreateBtn')}</Translation>
  ),
  className: '',
  isEdit: false
};

export default CreatorForm;
