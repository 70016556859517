import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Badge, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import plural from 'plural-ru';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';

import {
  disableIsArrived,
  getIsArrived,
  setDrawerVisible
} from 'store/notifications';
import { getEmployeeEntityChatsUnreadedCount } from 'store/operator';
import { getUserEmployee } from 'store/workspace';

import styles from './notification-bell.module.scss';

export const NotificationBell = ({ className }) => {
  const dispatch = useDispatch();

  const unreadedCount = useSelector(getEmployeeEntityChatsUnreadedCount);
  const employee = useSelector(getUserEmployee);
  const isArrived = useSelector(getIsArrived);

  const { t } = useTranslation(['NotificationsBell', 'Common']);

  const currentUnreadedCount =
    employee.chats && unreadedCount[employee.chats[0].uuid] > 0
      ? unreadedCount[employee.chats[0].uuid]
      : 0;

  const showNotifications = () => dispatch(setDrawerVisible(true));

  const titleTooltip =
    currentUnreadedCount > 0
      ? `${currentUnreadedCount} ${plural(
          currentUnreadedCount,
          t('SingularFormNew', { ns: 'Common' }),
          t('PluralFormNew', { ns: 'Common' })
        )} ${plural(
          currentUnreadedCount,
          t('SingularFormNotification', { ns: 'Common' }),
          t('PluralFormNotification', { ns: 'Common' })
        )}`
      : t('NoUpdatesTip');

  useEffect(() => {
    let timer;

    if (isArrived) {
      timer = setTimeout(() => {
        dispatch(disableIsArrived());
      }, 2000);
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArrived]);

  return (
    <Tooltip
      title={titleTooltip}
      mouseEnterDelay={0.5}
      getPopupContainer={triggerNode => triggerNode.parentNode}
    >
      <Button className={className} onClick={showNotifications} type="text">
        <Badge
          offset={[-1, 3]}
          count={currentUnreadedCount}
          style={{
            minWidth: 15,
            height: 15,
            lineHeight: '15px',
            fontSize: 10,
            fontWeight: 'bold',
            padding: '0 3px'
          }}
          className={classnames(styles.badge, {
            [styles.animationPulse]: isArrived
          })}
        >
          <Icon
            type="bell"
            size={20}
            className={classnames({
              [styles.animationRing]: isArrived
            })}
            color="black-55"
          />
        </Badge>
      </Button>
    </Tooltip>
  );
};

NotificationBell.propTypes = {
  className: PropTypes.string
};

NotificationBell.defaultProps = {
  className: undefined
};

export default NotificationBell;
