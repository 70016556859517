import React, { useEffect } from 'react';
import { Button, Typography, Tabs, Alert } from 'antd';
import Icon from 'components/common/icon';

import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  TASK_TYPES,
  TYPE_MEETING,
  TYPE_TASK,
  TYPE_ACQUAINTANCE,
  TYPE_AGREEMENT,
  TASKS,
  TEMPLATES,
  VIDEOS,
  SCHEDULER
} from '../../../../../../constants';
import TemplateView from '../../../../template-view';
import VideoBtn from '../../../../../common/video-btn';

import styles from './choose-type.module.scss';

const { Text } = Typography;
const { TabPane } = Tabs;

const SelectTypeStep = ({
  values,
  onSubmit,
  activeTab,
  setActiveTab,
  drawerVisible,
  isBacklog,
  isSprints
}) => {
  const { state } = useLocation();

  const { t } = useTranslation('AddTaskType');

  const handleSubmit = async (event, type) => {
    event.preventDefault();

    onSubmit(type);
  };

  const createFromTemplate = template => {
    onSubmit(template.kind, template);
  };

  useEffect(() => {
    if ((state || {}.initialData) && (state.initialData || {}).activeTab) {
      setActiveTab(state.initialData.activeTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const renderType = (types = []) =>
    TASK_TYPES.map(type => {
      const isSla =
        (values.taskInfo || isBacklog || isSprints) && type.id !== TYPE_TASK;

      if (!types.find(item => item === type.id) || isSla) return undefined;

      return (
        <Button
          key={type.id}
          className={classnames(styles.block, {
            [styles.disabledBlock]: isSla
          })}
          data-qa="qa-o2bbzbsyhmb3k5r"
          style={{ background: type.color }}
          onClick={event => handleSubmit(event, type.id)}
          disabled={isSla}
        >
          <Icon
            component={type.icon}
            className={styles.icon}
            style={{ color: type.iconColor }}
          />
          <Text
            className={styles.label}
            style={{ color: type.textColor }}
            data-qa="qa-mugjgxojxlhvhdb"
          >
            {t(type.label)}
          </Text>
          <Text className={styles.description} data-qa="qa-ljmfwjeadndamxw">
            {t(type.description)}
          </Text>
        </Button>
      );
    });

  return (
    <div className={styles.root} data-qa="qa-d4gt8mmrwzteze1">
      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        className={styles.tabs}
        data-qa="qa-gc6fojbr3l1fjkn"
      >
        <TabPane
          tab={t('NewTaskTab')}
          key={TASKS}
          className={styles.tab}
          data-qa="qa-qsbmou6029x4y2v"
        >
          {values.taskInfo && (
            <Alert
              message={t('SLATaskTypeWarning')}
              type="warning"
              className={styles.alertSla}
            />
          )}

          {(isSprints || isBacklog) && (
            <Alert
              message={t('SprintsAndBacklogWarning')}
              type="warning"
              className={styles.alertSla}
            />
          )}

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text className={styles.title} data-qa="qa-fof671ovs55m00q">
              {t('ChooseTaskType')}
            </Text>
            <VideoBtn slug={VIDEOS.typeTask} />
          </div>

          <div className={styles.container} data-qa="qa-vf4xd9hzl84p6gv">
            <Text className={styles.subTitle} data-qa="qa-3xdtlrbas5atevx">
              {t('MainTaskTypes')}
            </Text>
            {renderType([
              TYPE_TASK,
              TYPE_MEETING,
              TYPE_AGREEMENT,
              TYPE_ACQUAINTANCE
            ])}
          </div>
        </TabPane>

        <TabPane
          tab={t('TaskTemplatesTab')}
          key={TEMPLATES}
          className={styles.tab}
          data-qa="qa-kc7jilplcms0ybh"
        >
          <TemplateView
            drawerVisible={drawerVisible}
            onSubmit={createFromTemplate}
            values={values}
            isBacklog={isBacklog}
            isSprints={isSprints}
            onlyTaskKindAllowed={(isSprints || isBacklog) && TYPE_TASK}
          />
        </TabPane>

        {!values.taskInfo && !isSprints && !isBacklog && (
          <TabPane
            tab={t('RepetitiveTasksTab')}
            key={SCHEDULER}
            className={styles.tab}
            data-qa="qa-kc7jilplcms0ybh"
          >
            <TemplateView
              drawerVisible={drawerVisible}
              isSchedulerTemplates
              onSubmit={createFromTemplate}
            />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

SelectTypeStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
  isBacklog: PropTypes.bool,
  isSprints: PropTypes.bool
};

SelectTypeStep.defaultProps = {
  values: {},
  isBacklog: false,
  isSprints: false
};

export default SelectTypeStep;
