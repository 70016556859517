import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { EDIT_IDEA, MODAL, SELECTED_IDEA } from '../../constants';
import { getRouterUrlQuery } from '../../store/router';

export const useIdeaEditorModal = (defaultState = false) => {
  const [visible, setVisible] = useState(defaultState);

  const query = useSelector(getRouterUrlQuery);
  const modal = query[MODAL];
  const statusId = query[SELECTED_IDEA];

  useEffect(() => {
    setVisible(!!(modal === EDIT_IDEA && statusId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return [visible, setVisible];
};

export default useIdeaEditorModal;
