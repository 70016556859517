import { useReducer, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ITEMS_PER_PAGE } from 'constants/index';

import { updateAttachmentSubscription } from 'components/common/subscriptions/utils/update-attachment-subscription';
import { updateAttachmentSubscribers } from 'components/common/subscriptions/utils/update-attachment-subscribers';
import { updateAttachmentValidityDate } from 'components/common/validity-date/utils/update-attachment-validity-date';

import { fetchAttachmentsLocal } from 'store/attachments';
import { getWorkspaceId } from 'store/workspace';

const actionTypes = {
  setTotalItems: 'TOTAL',
  setIsLoading: 'LOADING',
  setHasError: 'ERROR',
  setEntries: 'ENTRIES'
};

const initialState = {
  totalItems: 0,
  isLoading: false,
  hasError: undefined,

  entries: []
};

const dataReducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.setTotalItems: {
      return { ...state, totalItems: payload };
    }

    case actionTypes.setHasError: {
      return { ...state, hasError: payload };
    }

    case actionTypes.setIsLoading: {
      return { ...state, isLoading: payload };
    }

    case actionTypes.setEntries: {
      return { ...state, entries: payload };
    }

    default: {
      throw new Error(type);
    }
  }
};

export const useData = ({ reducer = dataReducer, filters } = {}) => {
  const [data, localDispatch] = useReducer(reducer, initialState);
  const workspaceId = useSelector(getWorkspaceId);
  const dispatch = useDispatch();
  const limit = ITEMS_PER_PAGE;
  const entriesRef = useRef(data.entries);

  const setHasError = payload =>
    localDispatch({ type: actionTypes.setHasError, payload });
  const setTotalItems = payload =>
    localDispatch({ type: actionTypes.setTotalItems, payload });
  const setIsLoading = payload =>
    localDispatch({ type: actionTypes.setIsLoading, payload });

  const setEntries = payload =>
    localDispatch({ type: actionTypes.setEntries, payload });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setHasError(false);

      const offset = limit * (filters.currentPage - 1);

      const { entries, totalItems } = await dispatch(
        fetchAttachmentsLocal({ ...filters, isTrash: false, offset, limit })
      );

      setEntries(entries);
      setTotalItems(totalItems);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeoutId;
    if (!data.isLoading && !data.hasError) {
      timeoutId = setTimeout(() => {
        fetchData();
      }, 0);
    }

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [workspaceId, filters]);

  useEffect(() => {
    entriesRef.current = data.entries;
  }, [data.entries]);

  const handleSubscription = ({ data: file, isSubscribed }) =>
    updateAttachmentSubscription({
      attachments: entriesRef.current,
      setAttachments: setEntries,
      data: file,
      isSubscribed
    });

  const handleAfterChangeManageSubscription = ({
    added,
    deleted,
    userId,
    entityId
  }) => {
    updateAttachmentSubscribers({
      attachments: entriesRef.current,
      setAttachments: setEntries,
      data: { added, deleted, entityId, userId }
    });
  };

  const handleAfterChangeValidityDateCallback = ({ attachment }) => {
    updateAttachmentValidityDate({
      attachments: entriesRef.current,
      setAttachments: setEntries,
      data: attachment
    });
  };

  return {
    data,

    setHasError,
    setTotalItems,
    setIsLoading,
    setEntries,
    fetchData,
    subscribeCallback: handleSubscription,
    unsubscribeCallback: handleSubscription,
    changeManageSubscribersCallback: handleAfterChangeManageSubscription,
    changeValidityDateCallback: handleAfterChangeValidityDateCallback
  };
};

export default useData;
