import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';

import { Translation, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import MarkdownFormItem from 'components/common/new-editor/form/markdown-form-item';
import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { validateIsRequire, validateMaxLength } from 'utils/validators';

const RejectedForm = Form.create()(({ form, isLoading, onSubmit }) => {
  const { t } = useTranslation(['ReasonModal', 'Task']);

  const handleSubmit = event => {
    event.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values.reason);
      }
    });
  };

  return (
    <Form colon={false} hideRequiredMark onSubmit={handleSubmit}>
      <Form.Item
        label={t('DeclineTaskReasonHeading')}
        style={{ marginBottom: 24 }}
        data-qa="qa-sfabk809mmvg9dl"
      >
        <MarkdownFormItem
          name="reason"
          getFieldDecorator={form.getFieldDecorator}
          markdownProps={{
            showItems: {
              upload: false,
              mention: false,
              emoji: false,
              topToolbar: true
            },
            placeholder: t('EnterText', { ns: 'Task' }),
            isHtml: true
          }}
          validateTrigger="onSubmit"
          rules={[validateIsRequire(), validateMaxLength(500)]}
        />
      </Form.Item>

      <Button
        type="primary"
        size="large"
        htmlType="submit"
        loading={isLoading}
        style={{ width: '100%' }}
        data-qa="qa-q82zdnfon2kt3ox"
      >
        {t('SendBtn')}
      </Button>
    </Form>
  );
});

export const RejectedModal = ({ onSubmit, title, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async reason => {
    try {
      setIsLoading(true);
      await onSubmit(reason);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={644}
      destroyOnClose
      contentStyle={{ padding: 24 }}
      title={
        <Typography.Title
          level={2}
          style={{ marginBottom: 0, lineHeight: 1.2 }}
        >
          {title}
        </Typography.Title>
      }
      {...props}
    >
      <RejectedForm isLoading={isLoading} onSubmit={handleSubmit} />
    </Modal>
  );
};

RejectedModal.propTypes = {
  title: PropTypes.string
};

RejectedModal.defaultProps = {
  title: <Translation ns="Task">{t => t('RejectAgreementReason')}</Translation>
};

export default RejectedModal;
