import { createSelector } from 'reselect';
import { memoize } from 'lodash';

export const _getChannels = state => state.channels;

export const getChannels = createSelector(
  _getChannels,
  channels => channels.entries
);

export const getIsChannelsLoading = createSelector(
  _getChannels,
  channels => channels.isLoading
);

export const getIsChannelsLoaded = createSelector(
  _getChannels,
  channels => channels.isLoaded
);

export const getTotalItems = createSelector(
  _getChannels,
  channels => channels.totalItems
);

export const getCurrentPage = createSelector(
  _getChannels,
  channels => channels.currentPage
);

export const getItemsPerPage = createSelector(
  _getChannels,
  channels => channels.itemsPerPage
);

export const getChannelFactory = createSelector(getChannels, entries =>
  memoize(channelId => entries.find(channel => channel.id === channelId))
);

export const getEmailChannels = createSelector(
  _getChannels,
  channels => channels.emailChannels
);

export const getWhatsAppChannels = createSelector(
  _getChannels,
  channels => channels.whatsAppChannels
);

export const getEmailAndWhatsAppChannels = createSelector(
  getEmailChannels,
  getWhatsAppChannels,
  (emailChannels, whatsAppChannels) => ({
    emailChannels,
    whatsAppChannels
  })
);
