import React from 'react';
import { Button, Divider, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { HEADER_HEIGHT } from 'constants/styles';
import { TYPE_CONTACT } from 'constants/index';

import PopConfirm from 'components/common/pop-confirm';
import Icon from 'components/common/icon';

import { getIsOnlyUserProfile, logout } from 'store/user';
import { deleteDevice } from 'store/devices';
import { clearEmployeeCalendarFilter } from 'store/calendar';
import { setEntityChatHasNewMessages } from 'store/operator';

import useRoutesService from 'services/routes';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { useUpserviceWidgetContext } from 'providers';

export const SettingsMenu = () => {
  const dispatch = useDispatch();

  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);

  const amplitude = useAmplitude();
  const routesWithoutUrl = useRoutesService();
  const routes = useRoutesService({ returnUrl: true });

  const { openWidget } = useUpserviceWidgetContext();

  const { t } = useTranslation('Menu');

  const onLogout = async () => {
    const registrationId = localStorage.getItem('registrationId');
    if (registrationId) {
      await dispatch(deleteDevice({ registrationId }));
    }

    dispatch(
      setEntityChatHasNewMessages({ entityType: TYPE_CONTACT, value: false })
    );
    dispatch(clearEmployeeCalendarFilter());
    dispatch(logout());
    routesWithoutUrl.toLogin();
    amplitude.logoutEvent();
  };

  return (
    <>
      <Link
        to={routes.toUserProfile()}
        style={{ height: '100%', width: HEADER_HEIGHT }}
      >
        <Tooltip title={t('SettingsTip')} mouseEnterDelay={0.5}>
          <Button type="text" style={{ height: '100%', padding: 15 }}>
            <Icon type="setting" size={20} />
          </Button>
        </Tooltip>
      </Link>

      {isOnlyUserProfile && (
        <>
          <Divider style={{ margin: '0 2px', height: 32 }} type="vertical" />

          <Tooltip title={t('MenuSupport')} mouseEnterDelay={0.5}>
            <Button type="text" style={{ height: '100%' }} onClick={openWidget}>
              <Icon type="question-circle" size={20} />
            </Button>
          </Tooltip>
        </>
      )}

      <Divider style={{ margin: '0 2px', height: 32 }} type="vertical" />

      <Tooltip title={t('MenuLogOutBtn')} mouseEnterDelay={0.5}>
        <PopConfirm
          title={t('SignOutConfirm')}
          onConfirm={onLogout}
          style={{ width: 171 }}
          getPopupContainer={node => node}
          placement="bottomLeft"
        >
          <Button
            type="text"
            style={{
              height: '100%',
              padding: '0 20px'
            }}
          >
            <Icon type="logout" size={20} />
          </Button>
        </PopConfirm>
      </Tooltip>
    </>
  );
};

export default SettingsMenu;
