import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import { CloseIcon } from 'components/common/icon/icons';

import { getUserEmployee } from 'store/workspace';

import Subscriber from './subscriber';

import styles from './subscribers-drawer.module.scss';

const SubscribersList = ({ subscribers, onDelete, canManageSubscribers }) => {
  const { t } = useTranslation('FileSubscribers');

  const employee = useSelector(getUserEmployee);

  const allowDelete = subscriber =>
    canManageSubscribers || employee.id === subscriber.id;

  return (
    <div className={styles.content}>
      <Typography.Paragraph>
        {t('EmployersMonitoringUpdatesText')}
      </Typography.Paragraph>

      <div className={styles.list}>
        {subscribers.map(subscriber => (
          <div key={subscriber.id} className={styles.listItem}>
            <Subscriber info={subscriber} />

            {allowDelete(subscriber) && (
              <Button
                icon={<CloseIcon />}
                type="text"
                mood="none"
                onClick={() => onDelete(subscriber)}
                data-testid="delete-subscriber"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

SubscribersList.propTypes = {
  subscribers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      middleName: PropTypes.string,
      avatarFile: PropTypes.object,
      position: PropTypes.string
    })
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  canManageSubscribers: PropTypes.bool
};

SubscribersList.defaultProps = {
  canManageSubscribers: false
};

export default SubscribersList;
