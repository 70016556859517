import React from 'react';
import { Empty } from 'antd';
import Icon from 'components/common/icon';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UploadFiles from 'components/common/controls/upload-files';
import Typography from 'components/common/typography';
import { MyDriveIcon } from 'components/common/icons';

import { useFileUpload } from 'hooks';

import styles from './upload.module.scss';

export const Upload = ({ uploadFileServer, isOnlyUpload, ...props }) => {
  const { onUploadAttachment, onUpload } = useFileUpload();

  const { t } = useTranslation('UploadFile');

  const customRequest = () => {
    if (uploadFileServer) {
      return isOnlyUpload ? onUpload : onUploadAttachment;
    }

    return () => {};
  };

  return (
    <UploadFiles
      fullWidth
      showAttachments={false}
      className={styles.root}
      customRequest={customRequest()}
      {...props}
    >
      <Empty
        image={<Icon component={MyDriveIcon} />}
        imageStyle={{ height: 'auto' }}
        description={
          <Typography.Paragraph className={styles.title}>
            {t('DragnDropDesc')}
          </Typography.Paragraph>
        }
      />
    </UploadFiles>
  );
};

Upload.propTypes = {
  isOnlyUpload: PropTypes.bool,
  uploadFileServer: PropTypes.bool
};

Upload.defaultProps = {
  isOnlyUpload: false,
  uploadFileServer: true
};

export default Upload;
