import React from 'react';
import { Button, Input } from 'antd';
import { Form } from '@ant-design/compatible';

import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { TYPE_DATE_END, TITLE, TYPE_DATE_START } from 'constants/status';
import { DATE_FORMAT, DATE_PICKER_FORMAT } from 'constants/index';

import DatePicker from 'components/common/date-picker';

import {
  makeValidateDatesStartAfterEnd,
  validateIsRequire
} from 'utils/validators';

import styles from './styles.module.scss';

const BaseForm = Form.create({})(
  ({ form, onSubmit, isLoading, values = {}, sprints }) => {
    const { getFieldDecorator, validateFields, getFieldValue } = form;

    const { title, dateEnd, dateStart } = values;

    const formatDateValue = DATE_PICKER_FORMAT;

    const handleSubmit = async event => {
      event.preventDefault();

      validateFields((err, value) => {
        if (!err) {
          const sprintIsset = sprints.find(
            // TODO: sprint.id === +value.title ??????
            sprint => sprint.id === +value.title
          );

          onSubmit({
            ...value,
            sprintId: sprintIsset && sprintIsset.id
          });
        }
      });
    };

    const { t } = useTranslation(['AddEditSprint', 'Common']);

    const startDate = getFieldValue(TYPE_DATE_START) || new Date();
    const endDate = getFieldValue(TYPE_DATE_END);

    return (
      <Form
        className={styles.root}
        data-qa="qa-g26u8r9005o9tb9"
        onSubmit={handleSubmit}
        colon={false}
        hideRequiredMark
      >
        <div className={styles.fieldsWrap} data-qa="qa-bbrp7gh4gknwr2h">
          <div className={styles.field} data-qa="qa-ns1co7min8rdh6v">
            <Form.Item label={t('SprintName')}>
              {getFieldDecorator(TITLE, {
                initialValue: title || '',
                rules: [validateIsRequire()]
              })(
                <Input
                  placeholder={t('EnterSprintName')}
                  data-qa="qa-psb4o3thbb6u77q"
                  autoFocus
                  autoComplete="off"
                />
              )}
            </Form.Item>
          </div>

          <div className={styles.dates} data-qa="qa-urzdczjdwfu0fjo">
            <Form.Item
              label={t('SprintStartDate')}
              className={styles.dateStart}
            >
              {getFieldDecorator(TYPE_DATE_START, {
                valuePropName: 'selected',
                initialValue: dateStart
                  ? moment(dateStart, DATE_FORMAT).toDate()
                  : moment().toDate(),
                rules: [validateIsRequire()]
              })(
                <DatePicker
                  dateFormat={formatDateValue}
                  minDate={new Date()}
                  maxDate={endDate}
                  data-qa="qa-otfp94e1koqnswa"
                />
              )}
            </Form.Item>

            <Form.Item label={t('SprintDueDate')}>
              {getFieldDecorator(TYPE_DATE_END, {
                valuePropName: 'selected',
                initialValue: dateEnd && moment(dateEnd, DATE_FORMAT).toDate(),
                rules: [
                  validateIsRequire(),
                  {
                    validator: makeValidateDatesStartAfterEnd({
                      form,
                      checkSameDates: true,
                      checkSameFormat: 'day'
                    })
                  }
                ]
              })(
                <DatePicker
                  timeCaption={t('Time', { ns: 'Common' })}
                  dateFormat={formatDateValue}
                  minDate={startDate || new Date()}
                />
              )}
            </Form.Item>
          </div>
        </div>

        <Button
          className={styles.btnSteps}
          data-qa="qa-gbdfaypdw0zddyw"
          loading={isLoading}
          htmlType="submit"
          type="primary"
        >
          {t('SaveBtn')}
        </Button>
      </Form>
    );
  }
);

BaseForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  sprints: PropTypes.array,
  values: PropTypes.shape({
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    title: PropTypes.string
  })
};

BaseForm.defaultProps = {
  isLoading: false,
  isModalVisible: false,
  values: undefined,
  sprints: undefined
};

export default BaseForm;
