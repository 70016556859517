import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FilterMenu as Menu } from 'components/common/filter-menu';
import Icon from 'components/common/icon';

import { getUserEmployee } from 'store/workspace';

import styles from './filters.module.scss';

const MY_FILES = 'myFiles';
const FILTER_ALL = 'filterAll';
const MY_SUBSCRIPTIONS = 'mySubscriptions';

export const MenuFilter = ({ actions, menuFilters, isLoading }) => {
  const { t } = useTranslation('MyDrive');

  const user = useSelector(getUserEmployee);

  const { creators } = menuFilters;
  const { setMySubscriptions, setCreators } = actions;

  const [selectedKeys, setSelectedKeys] = useState([]);

  const resetFilter = () => {
    setMySubscriptions(false);
    setCreators([]);
  };

  const onChange = (setValue, value) => {
    setValue(value);
  };

  const handleMenuClick = filterKey => {
    if (selectedKeys.includes(filterKey)) return;

    resetFilter();

    switch (filterKey) {
      case MY_FILES:
        setSelectedKeys([MY_FILES]);
        onChange(setCreators, [{ value: user.id, label: user }]);
        break;

      case FILTER_ALL:
        setSelectedKeys([FILTER_ALL]);
        break;

      case MY_SUBSCRIPTIONS:
        setSelectedKeys([MY_SUBSCRIPTIONS]);
        onChange(setMySubscriptions, true);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (
      selectedKeys.length > 0 &&
      selectedKeys.includes(MY_FILES) &&
      creators.length !== 1
    ) {
      setSelectedKeys([FILTER_ALL]);
    }
  }, [creators]);

  useEffect(() => {
    resetFilter();
    setSelectedKeys([MY_FILES]);
    onChange(setCreators, [{ value: user.id, label: user }]);
  }, []);

  const menu = [
    {
      label: t('MyFilesFilter'),
      key: MY_FILES,
      onClick: () => handleMenuClick(MY_FILES),
      visible: true,
      disabled: isLoading
    },
    {
      label: t('AllDriveFilter'),
      key: FILTER_ALL,
      onClick: () => handleMenuClick(FILTER_ALL),
      visible: true,
      disabled: isLoading
    },
    {
      label: (
        <>
          <Icon
            type="subscribe-filled"
            color="brand"
            size={20}
            style={{ marginRight: '10px' }}
          />

          {t('MySubscriptionsDriveFilter')}
        </>
      ),
      key: MY_SUBSCRIPTIONS,
      onClick: () => handleMenuClick(MY_SUBSCRIPTIONS),
      visible: true,
      disabled: isLoading
    }
  ];

  const filteredMenu = menu.filter(item => item.visible);

  return (
    <Menu
      menu={filteredMenu}
      selectedKeys={selectedKeys}
      className={styles.filterMenu}
    />
  );
};

MenuFilter.propTypes = {
  actions: PropTypes.shape({
    setMySubscriptions: PropTypes.func.isRequired
  }),
  menuFilters: PropTypes.shape({
    creators: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.object])
      })
    ).isRequired
  }),
  isLoading: PropTypes.bool
};

MenuFilter.defaultProps = {
  actions: {
    setMySubscriptions: () => {}
  },
  menuFilters: {
    isMySubscriptions: false
  },
  isLoading: false
};

export default MenuFilter;
