import React, { forwardRef, useMemo } from 'react';
import { Select, Button, Input } from 'antd';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { RU_RU } from 'i18n-config';
import { useSelector } from 'react-redux';

import { DATE_PICKER_FORMAT, TIME_FORMAT } from 'constants/index';

import { Icon } from 'components/common/icon';

import { getUILanguage } from 'store/ui';

import styles from './date-picker.module.scss';

registerLocale('ru', ru);

const { Option } = Select;

const getYears = (length = 99) => {
  const year = new Date().getFullYear();

  return Array.from(
    { length: length + 5 },
    (v, i) => year - (length - (i + 1))
  );
};

const getMonths = () =>
  moment.months().map((month, index) => ({
    value: index,
    label: month
  }));

const CustomHeader = ({
  date,
  changeMonth,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}) => {
  const years = getYears();
  const months = getMonths();

  const selectProps = {
    getPopupContainer: trigger => trigger.parentNode,
    showArrow: false,
    className: styles.select,
    size: 'small'
  };

  const dropdownStyle = {
    minWidth: 120,
    fontSize: 12
  };

  return (
    <div className={styles.header} data-qa="qa-wyc2ainnxfretmz">
      <Button
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
        type="text"
        className={styles.btnPrev}
        data-qa="qa-3djov2vvnw07zs1"
      >
        <Icon type="arrow" side="left" size={20} />
      </Button>

      <Select
        onChange={changeMonth}
        value={moment(date).month()}
        dropdownStyle={{ ...dropdownStyle }}
        {...selectProps}
      >
        {months.map(({ value, label }) => (
          <Option value={value} key={value} className={styles.option}>
            {label}
          </Option>
        ))}
      </Select>

      <Select
        onChange={changeYear}
        value={moment(date).year()}
        dropdownStyle={{ ...dropdownStyle, minWidth: 100 }}
        {...selectProps}
      >
        {years.map(year => (
          <Option value={year} key={year}>
            {year}
          </Option>
        ))}
      </Select>

      <Button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        type="text"
        className={styles.btnNext}
        data-qa="qa-u2tls42vjuo14q8"
      >
        <Icon type="arrow" side="right" size={20} />
      </Button>
    </div>
  );
};

const CustomInput = forwardRef(
  ({ value, showIcon, onClick, inputProps, ...props }, ref) => {
    const { iconProps = {} } = inputProps;

    return (
      <Input
        ref={ref}
        value={value}
        onClick={onClick}
        suffix={
          showIcon ? (
            <Icon type="calendar" size={16} {...iconProps} onClick={onClick} />
          ) : null
        }
        {...props}
      />
    );
  }
);

export const DatePicker = forwardRef(
  ({
    className,
    containerClassName,
    popperClassName,
    wrapperClassname,
    calendarClassName,
    showIcon,
    isPrimaryTheme,
    dataTestId,
    inputProps,
    onChange,
    callback,
    ...props
  }) => {
    const language = useSelector(getUILanguage);

    const { t } = useTranslation('Common');

    const locale = useMemo(() => {
      if (language === RU_RU) {
        return ru;
      }

      return undefined;
    }, [language]);

    return (
      <div
        className={classnames(styles.rootWrap, wrapperClassname, {
          [styles.primary]: isPrimaryTheme
        })}
      >
        <ReactDatePicker
          timeFormat={TIME_FORMAT}
          dateFormat={DATE_PICKER_FORMAT}
          autoComplete="off"
          locale={locale}
          timeIntervals={5}
          timeCaption={t('Time')}
          className={classnames(styles.root, className)}
          wrapperClassName={classnames(styles.container, containerClassName)}
          popperClassName={classnames(styles.popperClassName, popperClassName)}
          calendarClassName={classnames(
            styles.calendarClassName,
            calendarClassName
          )}
          renderCustomHeader={CustomHeader}
          customInput={
            <CustomInput
              showIcon={showIcon}
              data-testid={dataTestId}
              inputProps={inputProps}
            />
          }
          onChange={v => {
            onChange(v);
            callback(v);
          }}
          {...props}
        />
      </div>
    );
  }
);

DatePicker.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  popperClassName: PropTypes.string,
  wrapperClassname: PropTypes.string,
  calendarClassName: PropTypes.string,
  showIcon: PropTypes.bool,
  isPrimaryTheme: PropTypes.bool,
  inputProps: PropTypes.object,
  callback: PropTypes.func
};

DatePicker.defaultProps = {
  className: undefined,
  containerClassName: undefined,
  popperClassName: undefined,
  wrapperClassname: undefined,
  calendarClassName: undefined,
  showIcon: true,
  isPrimaryTheme: false,
  inputProps: {},
  callback: () => {}
};

export default DatePicker;
