import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Divider, Tooltip } from 'antd';
import Icon from 'components/common/icon';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Translation, useTranslation } from 'react-i18next';

import { TASK_TYPES } from '../../../../constants';
import CopyLink from '../../../common/copy-link';
import useModalsService from '../../../../services/modals';

import styles from './icon-id.module.scss';

const { Paragraph } = Typography;

const IconId = ({
  className,
  object,
  icon,
  style,
  type,
  copyable,
  onClick,
  isSchedulerActive,
  link
}) => {
  const { t } = useTranslation('Tasks');

  const modals = useModalsService({ returnUrl: true });

  const task = TASK_TYPES.find(tt => (object.kind || '').includes(tt.id));

  const currentIcon = icon || (task || {}).icon;

  const hasOnClick = typeof onClick === 'function';

  const onClickId = () => hasOnClick && onClick();
  const onClickTaskParent = taskId => modals.tasks.showDetails({ id: taskId });

  return (
    <Paragraph
      className={classnames(styles.root, className, styles[type], {
        [styles.link]: hasOnClick,
        [styles.rootWithScheduler]: isSchedulerActive
      })}
      data-qa="qa-gt68yg6urqtjvs8"
      style={{
        marginBottom: 0,
        ...style
      }}
      onClick={onClickId}
    >
      {object.parent && (
        <>
          <Tooltip title={t('ParentTaskTip')}>
            <Link
              to={onClickTaskParent(object.parent)}
              style={{ padding: 0, height: 'auto', whiteSpace: 'nowrap' }}
              data-qa="qa-u7qspa8f63hvoss"
            >
              ID {object.parent}
            </Link>
          </Tooltip>

          <Divider
            type="vertical"
            style={{ marginTop: 4 }}
            data-qa="qa-w8lces8m1zqktr0"
          />
        </>
      )}

      {currentIcon && (
        <Tooltip
          title={
            task ? (
              <Translation ns={task.ns}>
                {translate => translate(task.label)}
              </Translation>
            ) : (
              undefined
            )
          }
          mouseEnterDelay={0.5}
        >
          <Icon className={styles.icon} component={currentIcon} />
        </Tooltip>
      )}

      <div className={styles.id} data-qa="qa-c6nyhalmfiakjrk">
        ID {object.id}
      </div>

      {copyable && (
        <CopyLink link={link || window.location.href} label={copyable.label} />
      )}
    </Paragraph>
  );
};

IconId.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.any,
  object: PropTypes.shape({
    id: PropTypes.number,
    kind: PropTypes.string,
    parent: PropTypes.number
  }),
  style: PropTypes.object,
  type: PropTypes.string,
  copyable: PropTypes.object,
  onClick: PropTypes.func,
  isSchedulerActive: PropTypes.bool,
  link: PropTypes.string
};

IconId.defaultProps = {
  className: undefined,
  icon: undefined,
  object: {},
  style: {},
  type: undefined, // link
  copyable: undefined,
  onClick: undefined,
  isSchedulerActive: false,
  link: null
};

export default IconId;
