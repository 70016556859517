import React from 'react';
import Icon from 'components/common/icon';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import Typography from 'components/common/typography';

import { RatingIcon } from '../icons';
import { useTheme } from '../theme';

import styles from './styles.module.scss';

export const Rate = ({ className, value, style }) => {
  const { variables } = useTheme();

  const getColor = number => {
    if (number > 0) {
      return variables.color['brand-40'];
    }

    if (number < 0) {
      return variables.color['red-60'];
    }

    return variables.color['black-85'];
  };

  const isSet = !!value || value === 0;

  return (
    <div className={classnames(className, styles.root)} style={style}>
      <Icon component={RatingIcon} style={{ color: getColor(value) }} />
      <Typography.Text>{isSet ? `${value} %` : '-'}</Typography.Text>
    </div>
  );
};

Rate.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  style: PropTypes.object
};

Rate.defaultProps = {
  className: undefined,
  value: undefined,
  style: {}
};

export default Rate;
