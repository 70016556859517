import React from 'react';
import { Translation } from 'react-i18next';

import {
  CALENDAR,
  CONTACTS,
  SETTINGS,
  WORKSPACE_PROFILE,
  SETTINGS_STATUS_NOTIFICATIONS,
  SETTINGS_PERSONAL,
  SETTINGS_STATUS_SECURITY,
  USER_PROFILE,
  SETTINGS_STATUS_PROFILE,
  EMPLOYEES,
  TEAM,
  ORGSTRUCTURE,
  PROJECTS,
  TASKS,
  ATTACHMENTS,
  BASKET,
  REPORTS,
  ASSETS,
  ALL,
  CONTRACTORS,
  SPRINTS,
  STATUS_BACKLOG,
  STATUS_IN_WORK,
  INTEGRATIONS,
  CHATS,
  DASHBOARD,
  NOTIFICATIONS,
  CATEGORIES,
  SETTINGS_STATUS_INTEGRATIONS,
  TEMPLATES,
  FUNNELS_SETTINGS,
  DEALS
} from 'constants/index';

import Icon from 'components/common/icon';

import getIsDemoModeActive from 'utils/get-is-demo-mode-active';

export const ROUTES = {
  [DASHBOARD]: {
    id: DASHBOARD,
    title: 'MenuDashboard',
    icon: <Icon type="appstore" size={20} />,
    count: 0,
    children: {
      [NOTIFICATIONS]: {
        id: NOTIFICATIONS,
        title: <Translation ns="Dashboard">{t => t('FeedTab')}</Translation>,
        count: 0,
        allowShowInMenu: false
      },
      [CALENDAR]: {
        id: CALENDAR,
        title: (
          <Translation ns="Dashboard">{t => t('CalendarTab')}</Translation>
        ),
        count: 0,
        allowShowInMenu: false
      }
    }
  },
  [TASKS]: {
    id: TASKS,
    title: 'MenuTasks',
    icon: <Icon type="snippets" size={20} />,
    count: 0,
    children: {
      [ALL]: {
        id: ALL,
        title: <Translation ns="Tasks">{t => t('TasksTab')}</Translation>,
        count: 0,
        allowShowInMenu: false
      },
      [PROJECTS]: {
        id: PROJECTS,
        title: <Translation ns="Tasks">{t => t('ProjectsTab')}</Translation>,
        count: 0,
        allowShowInMenu: false,
        children: {
          [STATUS_IN_WORK]: {
            id: STATUS_IN_WORK,
            title: (
              <Translation ns="Backlog">
                {t => t('TasksInProgressTab')}
              </Translation>
            ),
            count: 0,
            allowShowInMenu: false
          },
          [STATUS_BACKLOG]: {
            id: STATUS_BACKLOG,
            title: (
              <Translation ns="Backlog">{t => t('BacklogTab')}</Translation>
            ),
            count: 0,
            allowShowInMenu: false
          },
          [SPRINTS]: {
            id: SPRINTS,
            title: (
              <Translation ns="Backlog">{t => t('SprintsTab')}</Translation>
            ),
            count: 0,
            allowShowInMenu: false
          }
        }
      }
    }
  },
  [CONTACTS]: {
    id: CONTACTS,
    title: 'MenuContacts',
    icon: <Icon type="phone-and-message" size={20} />,
    count: 0,
    children: {
      [CHATS]: {
        id: CHATS,
        title: (
          <Translation ns="Contacts">
            {t => t('ContactsAndChatsTab')}
          </Translation>
        ),
        count: 0,
        allowShowInMenu: false
      },
      [DEALS]: {
        id: DEALS,
        title: <Translation ns="Contacts">{t => t('Deals')}</Translation>,
        count: 0,
        allowShowInMenu: false
      },
      [CONTRACTORS]: {
        id: CONTRACTORS,
        title: (
          <Translation ns="Contacts">{t => t('ContractorsTab')}</Translation>
        ),
        count: 0,
        allowShowInMenu: false
      },
      [INTEGRATIONS]: {
        id: INTEGRATIONS,
        title: (
          <Translation ns="Contacts">
            {t => t('ChannelSettingsTab')}
          </Translation>
        ),
        count: 0,
        allowShowInMenu: false
      },
      [FUNNELS_SETTINGS]: {
        id: FUNNELS_SETTINGS,
        title: (
          <Translation ns="Contacts">{t => t('FunnelsSettings')}</Translation>
        ),
        count: 0,
        allowShowInMenu: false
      }
    }
  },
  [ASSETS]: {
    id: ASSETS,
    title: 'MenuJournals',
    icon: <Icon type="book" size={20} />,
    count: 0,
    children: {
      [CATEGORIES]: {
        id: CATEGORIES,
        title: (
          <Translation ns="Journals">{t => t('AllJournalsTab')}</Translation>
        ),
        count: 0,
        allowShowInMenu: false
      },
      [CALENDAR]: {
        id: CALENDAR,
        title: (
          <Translation ns="Journals">
            {t => t('BookingCalendarTab')}
          </Translation>
        ),
        count: 0,
        allowShowInMenu: false
      }
    }
  },
  [TEAM]: {
    id: TEAM,
    title: 'MenuTeam',
    icon: <Icon type="big-team" size={20} />,
    count: 0,
    children: {
      [ORGSTRUCTURE]: {
        id: ORGSTRUCTURE,
        title: ORGSTRUCTURE,
        count: 0,
        allowShowInMenu: false
      },
      [EMPLOYEES]: {
        id: EMPLOYEES,
        title: EMPLOYEES,
        count: 0,
        allowShowInMenu: false
      }
    }
  },
  // Функционал заморожен ID-2167
  // [IDEAS]: {
  //   id: IDEAS,
  //   title: 'Идеи',
  //   icon: <Icon type="bulb" />,
  //   count: 0
  // },
  [REPORTS]: {
    id: REPORTS,
    title: 'MenuReports',
    icon: <Icon type="line-chart" size={20} />,
    count: 0,
    children: {
      [ALL]: {
        id: ALL,
        title: (
          <Translation ns="Reports">{t => t('SystemReportsTab')}</Translation>
        ),
        count: 0,
        allowShowInMenu: false
      },
      [TEMPLATES]: {
        id: TEMPLATES,
        title: (
          <Translation ns="Reports">{t => t('UserReportsTab')}</Translation>
        ),
        count: 0,
        allowShowInMenu: false
      }
    }
  },
  // [CALENDAR]: {
  //   id: CALENDAR,
  //   title: 'Календарь',
  //   icon: <Icon type="calendar" size={20} />,
  //   count: 0
  // },
  [SETTINGS]: {
    id: SETTINGS,
    title: 'MenuSettings',
    icon: <Icon type="setting" />,
    count: 0,
    allowShowInMenu: false,
    children: {
      [WORKSPACE_PROFILE]: {
        id: WORKSPACE_PROFILE,
        title: WORKSPACE_PROFILE,
        count: 0,
        children: {
          [SETTINGS_STATUS_PROFILE]: {
            id: SETTINGS_STATUS_PROFILE,
            title: SETTINGS_STATUS_PROFILE,
            count: 0
          },
          [SETTINGS_STATUS_NOTIFICATIONS]: {
            id: SETTINGS_STATUS_NOTIFICATIONS,
            title: SETTINGS_STATUS_NOTIFICATIONS,
            allowShowInMenu: false,
            count: 0
          }
        }
      },
      [USER_PROFILE]: {
        id: USER_PROFILE,
        title: USER_PROFILE,
        allowShowInMenu: false,
        count: 0,
        children: {
          [SETTINGS_PERSONAL]: {
            id: SETTINGS_PERSONAL,
            allowShowInMenu: false,
            title: (
              <Translation ns="AccountSettings">
                {t => t('AccountInfoTab')}
              </Translation>
            ),
            count: 0
          },
          [SETTINGS_STATUS_SECURITY]: {
            id: SETTINGS_STATUS_SECURITY,
            title: (
              <Translation ns="AccountSettings">
                {t => t('SafetyTab')}
              </Translation>
            ),
            allowShowInMenu: false,
            count: 0
          },
          [SETTINGS_STATUS_INTEGRATIONS]: {
            id: SETTINGS_STATUS_INTEGRATIONS,
            title: (
              <Translation ns="AccountSettings">
                {t => t('IntegrationsTab')}
              </Translation>
            ),
            allowShowInMenu: false,
            count: 0
          }
        }
      }
    }
  },
  [ATTACHMENTS]: {
    id: ATTACHMENTS,
    title: 'MenuDrive',
    icon: <Icon type="folder-open" size={20} />,
    count: 0,
    allowShowInMenu: !getIsDemoModeActive(),
    children: {
      [ALL]: {
        id: ALL,
        title: <Translation ns="MyDrive">{t => t('AllFilesTab')}</Translation>,
        count: 0,
        allowShowInMenu: false
      },
      [BASKET]: {
        id: BASKET,
        title: <Translation ns="MyDrive">{t => t('TrashTab')}</Translation>,
        count: 0,
        allowShowInMenu: false
      }
    }
  }
};

export default ROUTES;
