import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Icon from '../icon';

const FavoriteSwitch = ({ isFavorite, className, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('Tasks');

  const handleChange = async event => {
    try {
      setIsLoading(true);

      await onChange(event);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Tooltip
      title={t(
        isFavorite ? 'RemoveFromFavoritesAction' : 'AddToFavoritesAction'
      )}
      mouseEnterDelay={0.5}
    >
      <Spin size="small" spinning={isLoading} wrapperClassName={className}>
        <Icon
          type="star"
          theme={isFavorite ? 'filled' : undefined}
          color={isFavorite ? 'brand' : 'black-55'}
          onClick={handleChange}
          size={20}
          style={{ display: 'flex' }}
        />
      </Spin>
    </Tooltip>
  );
};

FavoriteSwitch.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

FavoriteSwitch.defaultProps = {
  className: undefined
};

export default FavoriteSwitch;
