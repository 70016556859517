import React from 'react';
import { message, Typography } from 'antd';
import moment from 'moment';
import { Translation } from 'react-i18next';

import { MESSAGE_DURATION } from 'constants/units';
import { TYPE_DATE_END, TYPE_DATE_START } from 'constants/status';

import isValidPhoneNumber from 'components/common/controls/phone-input/helpers';

import { ERROR_NOTICE_TYPE, showNoticeMessage } from 'services/notice';

const { Text } = Typography;

// eslint-disable-next-line no-useless-escape
const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateIsNumber = () => ({
  pattern: new RegExp(/^([0-9])+$/),
  message: <Translation ns="Errors">{t => t('NumberPattern')}</Translation>
});

export const validateIsPhone = (_, value, callback) => {
  if (!isValidPhoneNumber(value)) {
    callback(
      <Translation ns="Errors">
        {t => t('ValidateIsPhone', { value })}
      </Translation>
    );
  }

  callback();
};

export const validateEmail = () => ({
  type: 'email',
  message: <Translation ns="Errors">{t => t('ValidateEmail')}</Translation>
});

export const validateIsRequire = () => ({
  required: true,
  message: <Translation ns="Errors">{t => t('RequiredField')}</Translation>
});

export const validateMinLength = value => ({
  min: value,
  message: (
    <Translation ns="Errors">
      {t => t('ValidateMinLength', { value })}
    </Translation>
  )
});

export const validateMaxLength = value => ({
  max: value,
  message: (
    <Translation ns="Errors">
      {t => t('ValidateMaxLength', { value })}
    </Translation>
  )
});

export const validateStatusEmail = email => {
  if (email && !email.match(emailRegex)) {
    return 'error';
  }

  return 'success';
};

export const getValidEmails = (values, allowShowMessage = true) =>
  values.reduce((acc, cur) => {
    if (!cur) {
      return acc;
    }

    if (cur && !cur.match(emailRegex)) {
      if (allowShowMessage) {
        message.warning(
          <Text>
            <Text strong>{cur}</Text>{' '}
            <Translation ns="Errors">{t => t('InvalidEmail')}</Translation>
          </Text>,
          MESSAGE_DURATION
        );
      }

      return acc;
    }

    return [...acc, cur];
  }, []);

export const isNewLine = event =>
  (event.ctrlKey && event.keyCode === 13) ||
  (event.metaKey && event.keyCode === 13) ||
  (event.shiftKey && event.keyCode === 13);

export const makeValidateDatesStartAfterEnd =
  ({ form, checkSameDates, checkSameFormat }) =>
  (rule, value, callback) => {
    const start = form.getFieldValue(TYPE_DATE_START);
    const end = form.getFieldValue(TYPE_DATE_END);
    const isAfter = start && moment(start).isAfter(end);
    const isSame = checkSameDates
      ? start && moment(start).isSame(end, checkSameFormat)
      : false;

    if (isSame) {
      return callback(
        <Translation ns="Errors">
          {t => t('DateStartCannotBeEqualDateEnd')}
        </Translation>
      );
    }

    if (isAfter) {
      return callback(
        <Translation ns="Errors">
          {t => t('DateStartCannotBeAfterDateEnd')}
        </Translation>
      );
    }

    return callback();
  };

export const validateFileUpload = ({
  file,
  maxSize,
  allowedTypes,
  isImage
}) => {
  const notAllowedExtensions = ['.exe', '.msi'];
  const formatMap = {
    'image/jpeg': 'jpeg',
    'image/png': 'png',
    'image/svg+xml': 'svg',
    'image/gif': 'gif'
  };

  const allowedFormats = allowedTypes.map(type => formatMap[type]);

  if (
    notAllowedExtensions.some(ext => file[0].name.toLowerCase().endsWith(ext))
  ) {
    showNoticeMessage({
      customContent: (
        <Translation ns="Errors">{t => t('MsiExeNotAllowed')}</Translation>
      ),
      type: ERROR_NOTICE_TYPE
    });

    return false;
  }

  if (isImage && !allowedTypes.includes(file[0].type)) {
    showNoticeMessage({
      customContent: (
        <Translation ns="Errors">
          {t => t('AllowedFormats', { formats: allowedFormats.join(', ') })}
        </Translation>
      ),
      type: ERROR_NOTICE_TYPE
    });

    return false;
  }

  if (file[0].size > maxSize) {
    showNoticeMessage({
      customContent: (
        <Translation ns="Errors">
          {t => t('FileSizeMustNotExceed', { mb: maxSize / 1024 / 1024 })}
        </Translation>
      ),
      type: ERROR_NOTICE_TYPE
    });

    return false;
  }

  return true;
};
