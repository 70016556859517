import { createSelector } from 'reselect';
import { memoize } from 'lodash';

import { CUSTOMER } from 'constants/index';

export const getOrderStatuses = state => state.orderStatuses;

export const getOrderStatusesEntries = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.entries
);

export const getIsOrdersLoaded = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.isLoaded
);

export const getIsOrdersLoading = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.isLoading
);

export const getCurrentPage = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.currentPage
);

export const getTotalItems = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.totalItems
);

export const getItemsPerPage = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.itemsPerPage
);

export const getOrderFactory = createSelector(
  getOrderStatusesEntries,
  entries => memoize(statusId => entries.find(status => status.id === statusId))
);

export const getOrderStatusesByOrderFactory = createSelector(
  getOrderStatusesEntries,
  entries =>
    memoize(orderId => entries.find(status => status.order.id === orderId))
);

export const getError = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.error
);

export const getHasError = createSelector(getError, error => error !== null);

export const getNewOrders = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.newOrders
);

export const getFilterOrders = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.filter
);

export const getFilterSearchOrders = createSelector(
  getFilterOrders,
  filter => filter.search
);

export const getFilterSubStatus = createSelector(
  getFilterOrders,
  filter => filter.subStatus
);

export const getFilterStatus = createSelector(
  getFilterOrders,
  filter => filter.status
);

export const getFilterCategoriesOrders = createSelector(
  getFilterOrders,
  filter => filter.categories
);

export const getFilterEndDateRangeOrders = createSelector(
  getFilterOrders,
  filter => filter.endDateRange || {}
);

export const getFilterEmployeesOrders = createSelector(
  getFilterOrders,
  filter => filter.employees
);

export const getFilterCounterparts = createSelector(
  getFilterOrders,
  filter => filter.counterparts
);

export const getFilterControllers = createSelector(
  getFilterOrders,
  filter => filter.controllers
);

export const getFilterCounterpartWorkspaces = createSelector(
  getFilterOrders,
  filter => filter.counterpartWorkspaces
);

export const getFilterActiveRoleOrders = createSelector(
  getFilterOrders,
  filter => (filter.activeRole || {}).value
);

export const getFilterIsOutdated = createSelector(
  getFilterOrders,
  filter => filter.isOutdated
);

export const getFilterPaid = createSelector(
  getFilterOrders,
  filter => filter.paid
);

export const getIsCustomerFilter = createSelector(
  getFilterActiveRoleOrders,
  activeRole =>
    activeRole === undefined ? activeRole : activeRole === CUSTOMER
);

export const getFilterOrderingOrders = createSelector(
  getFilterOrders,
  filter => filter.ordering
);

export const getFilterIsFavorite = createSelector(
  getFilterOrders,
  filter => filter.isFavorite
);

// V2

export const getOrderStatusesIsAllLoading = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.isAllLoading
);

export const getOrderStatusesFilter = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.filter
);

export const getOrderStatusesFilterKind = createSelector(
  getOrderStatusesFilter,
  filter => filter.kind
);

export const getOrderStatusesFilterSearch = createSelector(
  getOrderStatusesFilter,
  filter => filter.search
);

export const getOrderStatusesFilterSearchKind = createSelector(
  getOrderStatusesFilter,
  filter => filter.searchKind
);

export const getOrderStatusesOrdering = createSelector(
  getOrderStatusesFilter,
  filter => filter.ordering
);

export const getSelectedOrderStatus = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.selected
);

export const getOrderStatusesItemsPerPage = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.itemsPerPage
);

export const getOrderStatusesPageData = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.pageData
);

export const getCollapsedOrderStatusContractorPanel = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.collapsedContractorPanel
);

export const getOrderStatusSmallListOpened = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.smallListOpened
);

export const getContactsOrderStatuses = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.contacts.entries
);

export const getSelectedContactId = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.contacts.selectedContactId
);

export const getSelectedContactTotalItems = createSelector(
  getOrderStatuses,
  orderStatuses => orderStatuses.contacts.totalItems
);

export const getSelectedContactOrdering = createSelector(
  getOrderStatuses,
  orderStatuses => (orderStatuses.contacts || {}).ordering
);
