import { createSelector } from 'reselect';
import { memoize } from 'lodash';

import { PUBLIC_IDEA } from '../../../constants';

export const getIdeas = state => state.ideas.publicIdeas;

export const getFilterIdeasPublic = createSelector(
  getIdeas,
  ideas => ideas.filter
);

export const getFilterSearchIdeasPublic = createSelector(
  getFilterIdeasPublic,
  filter => filter.search
);

export const getIsIdeasLoaded = createSelector(
  getIdeas,
  ideas => ideas.isLoaded
);

export const getIsIdeasLoading = createSelector(
  getIdeas,
  ideas => ideas.isLoading
);

export const getHasErrorIdeas = createSelector(
  getIdeas,
  ideas => !!ideas.error
);

export const getHasMoreIdeas = createSelector(getIdeas, ideas => ideas.hasMore);

export const getItemsPerPage = createSelector(
  getIdeas,
  ideas => ideas.itemsPerPage
);

export const getEntriesPublicIdeas = createSelector(
  getIdeas,
  ideas => ideas.entries
);

export const getIdea = createSelector(getEntriesPublicIdeas, entries =>
  memoize(id => {
    const idea = entries.find(item => item.id === id);
    return idea && { ...idea, type: PUBLIC_IDEA };
  })
);

export const getAllEntriesLength = createSelector(
  getEntriesPublicIdeas,
  entries => entries.length
);
