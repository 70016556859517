import { createAction, combineActions } from 'redux-actions';

import { flatten } from 'lodash';

function isPromise(p) {
  return p && p.then && p.catch;
}

export const combineActionsThunk = (...actions) =>
  combineActions(
    ...flatten(
      actions.map(a => {
        if (a.SUCCEEDED !== undefined) {
          return [a.START, a.SUCCEEDED, a.FAILED, a.ENDED];
        }
        return a;
      })
    )
  );

export default (type, fn) => {
  const TYPE_START = `${type}/started`;
  const TYPE_SUCCEEDED = `${type}/succeeded`;
  const TYPE_FAILED = `${type}/failed`;
  const TYPE_ENDED = `${type}/ended`;

  const actionCreators = {
    [TYPE_START]: createAction(TYPE_START),
    [TYPE_SUCCEEDED]: createAction(TYPE_SUCCEEDED),
    [TYPE_FAILED]: createAction(TYPE_FAILED),
    [TYPE_ENDED]: createAction(TYPE_ENDED)
  };

  const successActionWithMeta = createAction(
    TYPE_SUCCEEDED,
    ({ payload }) => payload,
    ({ meta }) => meta
  );

  const factory = args => (dispatch, getState, extra) => {
    let result;

    const withArgs = action => ({
      ...action,
      args
    });

    dispatch(withArgs(actionCreators[TYPE_START]()));

    const succeededFn = data => {
      const action =
        data && data.payload
          ? successActionWithMeta(data)
          : actionCreators[TYPE_SUCCEEDED](data);

      dispatch(withArgs(action));

      const endedAction = actionCreators[TYPE_ENDED]();
      dispatch(withArgs(endedAction));

      return data;
    };

    const failedFn = err => {
      const failedAction = actionCreators[TYPE_FAILED](err);
      dispatch(withArgs(failedAction));

      const endedAction = actionCreators[TYPE_ENDED]();
      dispatch(withArgs(endedAction));

      throw err;
    };

    try {
      result = fn({ ...args, getState, dispatch, extra });
    } catch (error) {
      failedFn(error);
    }

    if (isPromise(result)) {
      return result.then(succeededFn, failedFn);
    }
    return succeededFn(result);
  };

  factory.NAME = type;
  factory.START = actionCreators[TYPE_START].toString();
  factory.SUCCEEDED = actionCreators[TYPE_SUCCEEDED].toString();
  factory.FAILED = actionCreators[TYPE_FAILED].toString();
  factory.ENDED = actionCreators[TYPE_ENDED].toString();

  factory.toString = () => type.toString();

  return factory;
};
