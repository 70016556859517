import React from 'react';
import { Form } from '@ant-design/compatible';
import PropTypes from 'prop-types';

import DescriptionEditor from '../editors/description-editor';

const MarkdownFormItem = ({
  itemProps,
  markdownProps,
  getFieldDecorator,
  name,
  initialValue,
  validateTrigger,
  rules
}) => (
  <Form.Item colon={false} {...itemProps}>
    {getFieldDecorator(name, {
      initialValue,
      validateTrigger,
      rules: [
        {
          transform: value => value.description
        },
        ...rules
      ]
    })(<DescriptionEditor {...markdownProps} />)}
  </Form.Item>
);

MarkdownFormItem.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.array,
  getFieldDecorator: PropTypes.func,
  initialValue: PropTypes.shape({
    description: PropTypes.string,
    fileList: PropTypes.array,
    subTasks: PropTypes.array
  }),
  markdownProps: PropTypes.object,
  validateTrigger: PropTypes.string,
  itemProps: PropTypes.object
};

MarkdownFormItem.defaultProps = {
  name: '',
  initialValue: { description: '', fileList: [] },
  getFieldDecorator: () => {},
  rules: [],
  markdownProps: {},
  validateTrigger: 'onChange',
  itemProps: {}
};

export default MarkdownFormItem;
