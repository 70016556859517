import { CancelToken } from 'axios';

export const OLD_REQUEST_CANCELED = 'old-request-canceled';

const createCancelToken = setCancel =>
  new CancelToken(cancelFn => setCancel(cancelFn));

const getRequestConfig = ({ method, url, config, body, cancelToken }) => {
  const requestConfig = { ...config, cancelToken };

  if (method.toLowerCase() === 'get') {
    return [url, requestConfig];
  }

  return [url, body, requestConfig];
};

export const makeCancelableRequest = (api, fn) => {
  let cancel;

  return (...args) => {
    if (typeof cancel === 'function') {
      cancel(OLD_REQUEST_CANCELED);
    }

    const fetch = (method, url, config = {}) => {
      const { body, ...restConfig } = config;
      const cancelToken = createCancelToken(c => {
        cancel = c;
      });
      const requestConfig = getRequestConfig({
        method,
        url,
        config: restConfig,
        body,
        cancelToken
      });

      return api[method](...requestConfig);
    };

    return fn(fetch, ...args);
  };
};

export default makeCancelableRequest;
