import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Spin } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import useQrCode from './use-qr-code';

import styles from './whatsapp-auth.module.scss';

const { Title, Paragraph, Text } = Typography;
const WhatsAppAuthModal = ({ visible, onClose, setSuccessedModalData }) => {
  const { t } = useTranslation('ConnectWhatsApp');

  const { data } = useQrCode({
    modalVisible: visible,
    onClose,
    setSuccessedModalData
  });

  const qrCodeHash = data && data.qr_code;

  const [prevQrCodeHash, setPrevQrCodeHash] = useState(null);

  useEffect(() => {
    let timer;

    if (qrCodeHash) {
      setPrevQrCodeHash(qrCodeHash);

      timer = setTimeout(() => setPrevQrCodeHash(null), 10000);
    } else {
      setPrevQrCodeHash(null);
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCodeHash]);

  return (
    <Modal
      destroyOnClose
      centered
      contentStyle={{ padding: '55px 48px 32px 24px' }}
      visible={visible}
      width={797}
      onClose={onClose}
    >
      <div className={styles.content}>
        <div>
          <Title>{t('ConnectWhatsAppHeading')}</Title>

          <Alert
            type="warning"
            message={
              <div>
                <Paragraph>{t('InstructionsHowToConnect')}</Paragraph>

                <Paragraph className={styles.mb0}>
                  1. {t('InstructionsHowToConnectDescrOne')}
                </Paragraph>

                <Paragraph className={styles.mb0}>
                  2. {t('InstructionsHowToConnectDescrTwo')}{' '}
                  <Text weight="semibold">
                    {t('InstructionsHowToConnectDescrTwoText')}
                  </Text>
                </Paragraph>

                <Paragraph className={styles.mb0}>
                  3. {t('InstructionsHowToConnectDescrThree')}
                </Paragraph>
              </div>
            }
          />

          <Button
            type="link"
            target="_blank"
            href="https://help.upservice.com/articles/121935-как-подключить-whatsapp-к-upservice"
            className={styles.moreDetailBtn}
          >
            {t('DetailsBtn')}
          </Button>
        </div>

        <div>
          <Spin spinning={!qrCodeHash} wrapperClassName={styles.spin}>
            <QRCodeCanvas value={qrCodeHash || ''} size={207} />
          </Spin>

          {!qrCodeHash && (
            <Text className={styles.loadingText} color="black-45">
              {t('QRWaitingTip')}
            </Text>
          )}

          {prevQrCodeHash && (
            <Text
              className={classnames(styles.loadingText, styles.fadeOut)}
              color="black-45"
            >
              {t('QRUpdatedTip')}
            </Text>
          )}
        </div>
      </div>
    </Modal>
  );
};

WhatsAppAuthModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  setSuccessedModalData: PropTypes.func
};

WhatsAppAuthModal.defaultProps = {
  visible: false,
  onClose: () => {},
  setSuccessedModalData: () => {}
};

export default WhatsAppAuthModal;
