import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Menu, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGrowthBook } from '@growthbook/growthbook-react';

import { CONTACTS, TYPE_CONTACT } from 'constants/status';

import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import { checkPaymentAccessByRoles } from 'components/settings-view/billing/utils';
import Button from 'components/common/button';
import MobileAppModal from 'components/common/modals/mobile-app';

import { getUserEmployee, getWorkspaceId } from 'store/workspace';
import { getEntityChatHasNewMessages } from 'store/operator';

import useRoutesService from 'services/routes';
import getIsDemoModeActive from 'utils/get-is-demo-mode-active';

import { MENU_ANDROID_AND_IOS_FEATURE_FLAG } from 'constants/index';
import useAmplitude from 'hooks/amplitude/use-amplitude';
import { getItemPath, canShowMenuItem } from './helpres';

import styles from './side-menu.module.scss';

const { Item } = Menu;
const { Text } = Typography;

export const SideMenu = ({
  className,
  theme,
  routes,
  mode,
  inlineIndent,
  collapsed,
  ...menuProps
}) => {
  const routesService = useRoutesService({ returnUrl: true });

  const workspaceId = useSelector(getWorkspaceId);
  const contactChatHasNewMessages = useSelector(state =>
    getEntityChatHasNewMessages(state)({ entityType: TYPE_CONTACT })
  );
  const employee = useSelector(getUserEmployee);

  const { t } = useTranslation('Menu');

  const growthbook = useGrowthBook();
  const amplitude = useAmplitude();

  const isMenuAndroidAndIosEnabled = growthbook.isOn(
    MENU_ANDROID_AND_IOS_FEATURE_FLAG
  );

  const [visibleMobileAppModal, setVisibleMobileAppModal] = useState(false);

  const handleMenuItemMobileClick = () => {
    setVisibleMobileAppModal(true);

    amplitude.mainMenuClickEvent({ type: 'mobile' });
  };

  const handleMenuItemClick = item => {
    amplitude.mainMenuClickEvent({ type: item.id });
  };

  const renderItem = (items, route) => {
    const item = items[route];

    if (!canShowMenuItem({ route, item, growthbook })) {
      return null;
    }

    const dotByRoute = {
      [CONTACTS]: contactChatHasNewMessages
    };

    const itemPath = getItemPath({ ...item, workspaceId });

    return (
      <Item
        key={itemPath.current}
        className={classnames(styles.menuItem, item.className)}
        title={t(item.title)}
      >
        <Link
          className={classnames(styles.itemContent, {
            [styles.withDot]: dotByRoute[route]
          })}
          to={itemPath.link}
          replace
          onClick={() => handleMenuItemClick(item)}
        >
          {item.icon}
          <Text>{t(item.title)}</Text>
        </Link>
      </Item>
    );
  };

  const isDemo = getIsDemoModeActive();

  return (
    <>
      <Menu
        className={classnames(styles.root, className)}
        mode={mode}
        theme={theme}
        subMenuOpenDelay={1}
        inlineIndent={inlineIndent}
        {...menuProps}
      >
        {Object.keys(routes).map(route => renderItem(routes, route))}
      </Menu>

      {!isDemo && (
        <div className={styles.dividerContainer}>
          <Divider
            style={{
              borderColor: 'rgba(191, 191, 195, 0.2)',
              marginTop: 16,
              marginBottom: 14
            }}
          />
        </div>
      )}

      {!isDemo && (
        <Menu
          className={classnames(styles.root, className)}
          mode={mode}
          theme={theme}
          subMenuOpenDelay={1}
          inlineIndent={inlineIndent}
          {...menuProps}
        >
          {checkPaymentAccessByRoles(employee.roles) && (
            <Item
              key={routesService.toBilling().replace(`/${workspaceId}`, '')}
              className={styles.menuItem}
              title={t('PlansBillingTab')}
            >
              <Link
                className={styles.itemContent}
                to={routesService.toBilling()}
              >
                <Icon type="dollar" size={20} />

                <Text className={styles.menuText}>{t('PlansBillingTab')}</Text>
              </Link>
            </Item>
          )}

          {isMenuAndroidAndIosEnabled && (
            <Item className={styles.menuItem} title={t('MenuAndroidandiOS')}>
              <Button
                type="link"
                className={styles.mobileModalBtn}
                onClick={handleMenuItemMobileClick}
              >
                <Icon type="tablet" size={20} color="black-85" />

                <Text style={{ maxWidth: 0 }}>{t('MenuAndroidandiOS')}</Text>
              </Button>
            </Item>
          )}
        </Menu>
      )}

      <MobileAppModal
        visible={visibleMobileAppModal}
        onClose={() => setVisibleMobileAppModal(false)}
      />
    </>
  );
};

// eslint-disable-next-line
const routesProps = PropTypes.shape({
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  count: PropTypes.number,
  allowShowInMenu: PropTypes.bool,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.element,
      count: PropTypes.number,
      allowShowInMenu: PropTypes.bool
    })
  )
});

SideMenu.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
  mode: PropTypes.string,
  inlineIndent: PropTypes.number,
  routes: PropTypes.object.isRequired, // routesProps
  collapsed: PropTypes.bool
};

SideMenu.defaultProps = {
  className: undefined,
  theme: 'dark',
  mode: 'inline',
  inlineIndent: 20,
  collapsed: false
};

export default SideMenu;
