import React from 'react';

export const TagIcon = props => (
  <svg
    width="131"
    height="118"
    viewBox="0 0 131 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M129.1 43.8431C128.913 43.5631 127.047 41.1367 123.127 36.1905C123.127 36.1905 120.328 32.6442 117.528 29.7512C117.341 29.5645 117.248 29.4712 117.061 29.2846C116.501 28.7246 115.941 28.1647 115.381 27.6981C114.821 27.1381 114.168 26.5782 113.608 26.1116C113.422 26.0182 113.328 25.8316 113.142 25.7383C112.302 24.9917 111.462 24.3384 110.529 23.5918C110.249 23.3119 109.875 23.1252 109.595 22.8452C108.475 21.912 107.262 21.0721 105.956 20.2322C105.862 20.1388 105.769 20.1388 105.769 20.0455C105.582 19.9522 105.489 19.8589 105.302 19.7656C104.369 19.1123 103.343 18.459 102.409 17.8058L102.316 17.7124C102.223 17.6191 102.129 17.6191 102.129 17.5258C101.943 17.4325 101.849 17.3391 101.663 17.2458C100.636 16.5925 99.5164 15.9393 98.4898 15.3793C93.637 12.6729 88.4109 10.2465 82.9981 8.28673C76.1854 5.86031 68.9995 4.18048 61.6269 3.43389C61.5336 3.43389 61.347 3.43389 61.2536 3.43389C60.3204 3.34057 59.3872 3.24725 58.4539 3.24725C58.3606 3.24725 58.174 3.24725 58.0806 3.24725H57.9873C52.3879 2.78063 46.9751 3.0606 41.5623 3.90051C40.2558 4.08716 38.9493 4.36713 37.5494 4.6471C36.2429 4.92707 35.0297 5.20705 33.9098 5.58034C32.6966 5.95364 31.4834 6.42025 30.3635 6.88687C15.1517 13.6995 12.632 29.6579 10.1122 46.1762C9.64561 49.3492 9.17899 52.6155 8.61905 55.7885C7.40584 62.6012 5.81934 69.3205 2.83298 75.1999C-1.17994 87.7986 0.0332657 98.9041 5.91266 106.557C6.09931 106.65 7.40584 107.77 8.33908 109.356C14.0318 114.862 22.6176 118.035 33.7231 117.849C42.5889 117.755 72.6391 119.435 98.0232 110.943C98.0232 110.943 101.756 109.73 106.609 107.583C124.154 99.744 128.54 91.8115 129.38 88.9184C129.753 87.5186 129.753 86.3987 129.753 86.3987C129.473 83.4123 126.674 81.6392 125.834 74.08C124.34 59.3348 133.86 52.0556 129.1 43.8431Z"
      fill="#EBF8F8"
    />
    <path
      d="M80.5752 85.2963L71.9599 107.291C71.2504 109.014 69.3247 109.824 67.6016 109.216L24.2213 91.9858C22.4982 91.2763 21.6874 89.3505 22.2955 87.6274L31.7216 63.9102L35.1677 67.2549L40.7423 72.5254L52.7023 68.6739L58.0742 76.0729L70.6423 71.3091L77.4332 81.0393L80.5752 85.2963Z"
      fill="#2D9EA3"
    />
    <path
      d="M58.1755 76.0731L52.7023 68.6741L40.6409 72.4243L35.0663 67.1538L28.0728 84.7898C27.7687 85.4992 27.7687 86.3101 28.0728 87.0196C28.0728 87.1209 28.1741 87.2223 28.1741 87.2223C28.2755 87.425 28.3768 87.6277 28.5796 87.8304C28.7823 88.0331 29.0863 88.2359 29.3904 88.4386C29.4918 88.4386 29.5931 88.5399 29.5931 88.5399L65.8786 102.831C65.9799 102.831 66.0813 102.932 66.0813 102.932C66.3853 103.034 66.6894 103.034 66.9935 103.034C67.1962 103.034 67.5002 102.932 67.703 102.932C67.8043 102.932 67.9057 102.831 68.007 102.831C68.7165 102.527 69.2233 101.919 69.5274 101.209L77.5345 81.0396L70.7436 71.3094L58.1755 76.0731Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M107.536 43.031L96.3868 26.0032V25.9018L94.6638 23.2666V23.1652L91.015 17.5906C90.2041 16.3744 88.7851 15.8676 87.3661 16.1716L86.9607 16.273L84.1227 16.9825L83.92 17.0839L58.0743 23.3679H57.9729L46.1143 53.2679L49.5604 56.6127L55.1349 61.8832L67.1963 58.133L72.5682 65.532L85.1363 60.6669L91.9272 70.3971L94.9678 74.7554L107.536 43.031ZM87.3661 28.9425C86.8594 30.2601 85.339 30.9696 83.92 30.4628C82.6024 29.9561 81.8929 28.4357 82.5011 27.0167C83.0078 25.6991 84.5282 24.9896 85.8458 25.4964C87.1634 26.1045 87.8729 27.6249 87.3661 28.9425Z"
      fill="#2D9EA3"
    />
    <path
      d="M102.266 44.2473L92.9409 29.2466L91.4206 26.814L88.7853 22.5571C88.3799 21.949 87.6704 21.4422 86.9609 21.3408C86.8595 21.3408 86.7582 21.3408 86.7582 21.3408C86.6568 21.3408 86.6568 21.3408 86.5555 21.3408C86.5555 21.3408 86.5555 21.3408 86.4541 21.3408C86.3528 21.3408 86.2514 21.3408 86.15 21.3408C86.0487 21.3408 85.9473 21.3408 85.9473 21.3408H85.846H85.7446L83.9202 21.8476L83.5148 21.949L61.1151 27.7262H61.0138L49.5605 56.5113L55.1351 61.7818L67.1965 58.0317L72.5683 65.4307L85.1365 60.6669L91.9273 70.3971L102.266 44.2473ZM83.9202 30.3615C82.6026 29.8547 81.8931 28.3344 82.5012 26.9154C83.008 25.5978 84.5283 24.8883 85.846 25.4964C87.1636 26.0032 87.8731 27.5235 87.265 28.9425C86.7582 30.2601 85.2378 30.9696 83.9202 30.3615Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M96.3869 25.9016L94.6638 23.2664V23.165C99.9343 16.8809 102.468 10.7996 100.948 7.65754C99.7316 5.225 97.8058 4.31279 97.8058 4.31279C97.6031 4.21144 95.3733 3.19788 92.9407 4.21144C88.8865 5.93449 87.9743 12.4213 87.1635 16.2728C87.1635 16.3742 87.1635 16.4755 87.1635 16.5769C86.9607 17.9959 86.758 19.6175 86.6567 21.3406C86.6567 21.442 86.6567 21.6447 86.6567 21.746C86.5553 23.2664 86.5553 24.8881 86.5553 26.6111C86.5553 26.8138 86.5553 27.0165 86.5553 27.2192C86.5553 28.0301 85.9472 28.7396 85.035 28.7396C84.8323 28.7396 84.6296 28.7396 84.4269 28.6382C83.9201 28.4355 83.5146 27.9287 83.5146 27.2192C83.5146 26.8138 83.5146 26.307 83.5146 25.9016C83.5146 24.6853 83.5146 23.4691 83.616 22.2528C83.616 22.1514 83.616 22.0501 83.616 21.9487C83.7174 20.327 83.8187 18.8067 84.0214 17.2864C84.0214 17.185 84.0214 17.0836 84.0214 16.9823C85.1363 8.36703 88.0757 2.58974 92.1299 1.27211C95.9814 0.055842 99.0221 1.37347 99.1235 1.47483C99.2248 1.47483 102.063 2.79245 103.786 6.23856C105.813 10.7996 102.975 18.1986 96.3869 25.9016Z"
      fill="#E2E2E2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.408 63.2008L104.293 71.0052C103.38 71.4106 103.583 72.8296 104.597 73.0323L107.637 73.4377C108.549 73.5391 108.854 74.654 108.144 75.2621L96.5895 85.0937C95.6773 85.9045 96.6908 87.4248 97.8058 86.8167L119.8 75.3635C120.712 74.8567 120.509 73.5391 119.496 73.3364L116.05 72.8296C115.239 72.7282 114.834 71.6133 115.442 71.0052L121.726 64.8225C122.537 64.113 121.523 62.694 120.408 63.2008Z"
      fill="#FEC458"
    />
    <path
      d="M97.9075 96.2424C97.2994 96.2424 96.7926 95.9383 96.4885 95.3302C96.1844 94.5194 96.4885 93.7085 97.198 93.3031L107.84 88.3366C108.651 88.0326 109.462 88.3366 109.867 89.0461C110.172 89.857 109.867 90.6678 109.158 91.0733L98.5156 96.0397C98.3129 96.141 98.1102 96.2424 97.9075 96.2424Z"
      fill="white"
    />
    <path
      d="M35.7757 59.349C35.1676 59.349 34.5594 58.9435 34.3567 58.3354L27.87 38.2669C27.5659 37.4561 28.0727 36.6452 28.8835 36.3411C29.6944 36.0371 30.5052 36.5439 30.8093 37.3547L37.2961 57.4232C37.6001 58.234 37.0933 59.0449 36.2825 59.349C36.0798 59.349 35.9784 59.349 35.7757 59.349Z"
      fill="white"
    />
    <path
      d="M26.0458 56.2071C25.6404 56.2071 25.1336 56.0044 24.8295 55.7003L16.5183 45.7674C16.0115 45.1593 16.1129 44.1457 16.721 43.6389C17.3292 43.1322 18.3427 43.2335 18.8495 43.8416L27.1607 53.7745C27.6675 54.3827 27.5661 55.3962 26.958 55.903C26.7553 56.1057 26.3499 56.2071 26.0458 56.2071Z"
      fill="white"
    />
    <path
      d="M76.4446 33.3972L61.8684 10.4827C60.1644 7.80394 56.2544 7.80394 54.5504 10.4827L39.9742 33.3971C38.1376 36.2841 40.2115 40.0613 43.6331 40.0613H72.7855C76.2071 40.0613 78.281 36.2841 76.4446 33.3972Z"
      fill="#F9716C"
    />
    <path
      d="M46.5248 33.3982L61.1011 10.4839C61.2187 10.299 61.3476 10.1283 61.4848 9.96897C59.6346 7.81927 56.1367 7.98999 54.5504 10.4839L39.9742 33.3982C38.1376 36.2851 40.2115 40.0623 43.6331 40.0623H50.1839C46.7623 40.0623 44.6884 36.2851 46.5248 33.3982Z"
      fill="#F7413B"
    />
    <path
      d="M58.9891 28.1685H57.4297C56.6063 28.1685 55.9326 27.4948 55.9326 26.6714V17.3145C55.9326 16.491 56.6063 15.8174 57.4297 15.8174H58.9891C59.8126 15.8174 60.4862 16.491 60.4862 17.3145V26.6714C60.4862 27.4948 59.8126 28.1685 58.9891 28.1685Z"
      fill="white"
    />
    <path
      d="M58.2094 36.2538C59.4669 36.2538 60.4862 35.2344 60.4862 33.977C60.4862 32.7196 59.4669 31.7002 58.2094 31.7002C56.952 31.7002 55.9326 32.7196 55.9326 33.977C55.9326 35.2344 56.952 36.2538 58.2094 36.2538Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="83.7142"
        y1="46.036"
        x2="46.0356"
        y2="95.3217"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="81.5717"
        y1="21.3408"
        x2="42.6431"
        y2="94.7859"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default TagIcon;
