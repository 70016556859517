import React from 'react';
import PropTypes from 'prop-types';
import MobileDetect from 'mobile-detect';
import { debounce } from 'lodash';

import { DeviceContext } from './context';

// TODO: use variables from theme
const SMALL_DEVICE_MAX_WIDTH = 768;
const MEDIUM_DEVICE_MAX_WIDTH = 1023;
const SMALL_DESCTOP_MAX_WIDTH = 1450;

export class DeviceDetectionProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceContextValue: {
        isMobile: this.isMobileBrowser(),
        isSmallWidth: this.isSmallWidth(),
        isMediumWidth: this.isMediumWidth(),
        isSmallDesctopWidth: this.isSmallDesctopWidth()
      }
    };
  }

  componentDidMount() {
    window.addEventListener('resize', debounce(this.handleResize, 200));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { deviceContextValue } = this.state;

    this.setState({
      deviceContextValue: {
        ...deviceContextValue,
        isSmallWidth: this.isSmallWidth(),
        isMediumWidth: this.isMediumWidth(),
        isSmallDesctopWidth: this.isSmallDesctopWidth()
      }
    });
  };

  isMobileBrowser = () => {
    const mobileDetect = new MobileDetect(window.navigator.userAgent);

    return !!mobileDetect.mobile();
  };

  isSmallWidth = () => {
    const isSmallViewportWidth = window.matchMedia(
      `(max-width: ${SMALL_DEVICE_MAX_WIDTH}px)`
    ).matches;
    const isSmallDeviceWidth = window.matchMedia(
      `(max-device-width: ${SMALL_DEVICE_MAX_WIDTH}px)`
    ).matches;

    return isSmallViewportWidth || isSmallDeviceWidth;
  };

  isMediumWidth = () => {
    const isMediumViewportWidth = window.matchMedia(
      `(max-width: ${MEDIUM_DEVICE_MAX_WIDTH}px)`
    ).matches;
    const isMediaDeviceWidth = window.matchMedia(
      `(max-device-width: ${MEDIUM_DEVICE_MAX_WIDTH}px)`
    ).matches;

    return (
      (isMediumViewportWidth || isMediaDeviceWidth) && !this.isSmallWidth()
    );
  };

  isSmallDesctopWidth = () => {
    const isSmallDesctopViewportWidth = window.matchMedia(
      `(max-width: ${SMALL_DESCTOP_MAX_WIDTH}px)`
    ).matches;
    const isSmallDesctopDeviceWidth = window.matchMedia(
      `(max-device-width: ${SMALL_DESCTOP_MAX_WIDTH}px)`
    ).matches;

    return (
      (isSmallDesctopViewportWidth || isSmallDesctopDeviceWidth) &&
      !this.isSmallWidth() &&
      !this.isMediumWidth()
    );
  };

  render() {
    const { children } = this.props;
    const { deviceContextValue } = this.state;

    return (
      <DeviceContext.Provider value={deviceContextValue}>
        {children}
      </DeviceContext.Provider>
    );
  }
}

DeviceDetectionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

DeviceDetectionProvider.defaultProps = {
  children: null
};

export { DeviceContext } from './context';
export default DeviceDetectionProvider;
