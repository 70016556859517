import { createSelector } from 'reselect';
import { memoize } from 'lodash';

export const getChats = state => state.chats;

/* ------- CHATS -------- */
export const getByStatusId = createSelector(
  getChats,
  chats => chats.byStatusId
);

export const getEntries = createSelector(getChats, chats => chats.entries);

export const getChatsInfoByStatusIdFactory = createSelector(
  [getByStatusId],
  byStatusId =>
    memoize(statusId => (byStatusId[+statusId] && byStatusId[+statusId]) || {})
);

export const getChatsByStatusIdFactory = createSelector(
  [getByStatusId, getEntries],
  (byStatusId, entries) =>
    memoize(statusId => {
      const chatIds =
        (byStatusId[+statusId] && byStatusId[+statusId].chatIds) || [];

      return chatIds.map(chatId => entries[chatId]);
    })
);

export const getTotalItemsChatsByStatusIdFactory = createSelector(
  getByStatusId,
  byStatusId =>
    memoize(statusId => (byStatusId[statusId] || {}).totalItems || 0)
);

export const getChatsOffsetFactory = createSelector(
  [getByStatusId, getEntries],
  (byStatusId, entries) =>
    memoize(statusId => {
      const chatIds =
        byStatusId[statusId] && (byStatusId[statusId].chatIds || []);

      return (
        Object.keys(entries)
          .map(parseFloat)
          .filter(key => chatIds.includes(key))
          // Так как с заказах возвращается только chatId и нам нужно подгружать chat
          // в массив добавляем фильтрацию по isLoaded, для того что бы загрузить чаты
          .filter(key => entries[key].isLoaded).length
      );
    })
);

export const getChatsByStatusIdLoadingFactory = createSelector(
  getChatsByStatusIdFactory,
  getChatsByStatusId =>
    memoize(statusId => {
      const chats = getChatsByStatusId(statusId);
      return Object.keys(chats).length > 0
        ? Object.values(chats)
            .map(c => c.isLoading)
            .reduce((a, b) => a && b)
        : false;
    })
);

export const getIsLoadingChatsFactory = createSelector(
  getByStatusId,
  byStatusId =>
    memoize(statusId => byStatusId[statusId] && byStatusId[statusId].isLoading)
);

export const getIsLoadedChatsFactory = createSelector(
  getByStatusId,
  byStatusId =>
    memoize(statusId => byStatusId[statusId] && byStatusId[statusId].isLoaded)
);

/* Берем кол-во новых сообщений для statusId */
export const getChatUnreadMessagesCountFactory = createSelector(
  getChatsByStatusIdFactory,
  getChatsByStatusId =>
    memoize(statusId => {
      const chats = getChatsByStatusId(statusId);

      return Object.keys(chats).length > 0
        ? Object.values(chats)
            .map(chat => {
              const isRecentExists = chat.messages.find(
                m => m.id === chat.messageRecent
              );

              if (!isRecentExists) {
                return chat.messageNewCount;
              }

              return chat.messages.filter(m => m.id > chat.messageRecent)
                .length;
            })
            .reduce((a, b) => a + b)
        : 0;
    })
);

export const getChatResponsesCountTotalFactory = createSelector(
  getChatsByStatusIdFactory,
  getChatsByStatusId =>
    memoize(statusId => {
      const chats = getChatsByStatusId(statusId);

      return Object.keys(chats).length > 0 ? Object.values(chats).length : 0;
    })
);

/* Берем кол-во откликов для statusId */
// Только для заказчика в заказах не берется кол-во откликов и сообщений,
// так как с сервера не возвращается chatId и некуда записывать инфу о сообщений и откликах
export const getChatResponsesCountFactory = createSelector(
  getChatsByStatusIdFactory,
  getChatsByStatusId =>
    memoize(statusId => {
      const chats = getChatsByStatusId(statusId);

      return Object.keys(chats).length > 0
        ? Object.values(chats).filter(c => c.messageRecent === null).length
        : 0;
    })
);
/* --------------------- */

/* ------- CHAT -------- */
export const getChatFactory = createSelector(getEntries, entries =>
  memoize(
    chatId =>
      Object.values(entries).find(statusChat => statusChat.id === chatId) || {}
  )
);

export const getMessageOffsetFactory = createSelector(getChatFactory, getChat =>
  memoize(chatId => (getChat(chatId).messages || []).length)
);

export const getMessageWithEvent = createSelector(getChatFactory, getChat =>
  memoize(chatId => getChat(chatId).withEvent)
);

export const getIsOpenedChatFactory = createSelector(getChatFactory, getChat =>
  memoize(chatId => getChat(chatId).isOpened || false)
);
export const getChatsIdsByStatusIdFactory = createSelector(
  getByStatusId,
  byStatusId =>
    memoize(
      statusId => (byStatusId[+statusId] && byStatusId[+statusId].chatIds) || []
    )
);

export const getChatLastMessageEventIsAccepted = createSelector(
  getChatFactory,
  getChat =>
    memoize(chatId => {
      let isAccepted = true;

      if (!chatId) return isAccepted;

      const messagesWithEvents = (getChat(chatId).messages || [])
        .filter(message => !!message.event)
        .reverse();

      if (!messagesWithEvents.length) return isAccepted;

      for (let i = 0; i < messagesWithEvents.length; i++) {
        const message = messagesWithEvents[i];

        if (message.event && message.event.isAccepted !== null) break;

        if (message.event && message.event.isAccepted === null) {
          isAccepted = false;
          break;
        }
      }

      return isAccepted;
    })
);
