import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Input, Divider, Typography, Alert, Checkbox } from 'antd';
import { Form } from '@ant-design/compatible';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { union, isEqual } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import {
  TYPE_ASSET,
  TYPE_TASK,
  TYPE_MEMBERS,
  TYPE_CONTROLLER,
  TYPE_DATE_START,
  TYPE_DATE_END,
  TYPE_TIME,
  TYPE_ADDRESS,
  TITLE,
  DESCRIPTION,
  DATE_PICKER_TIME_FORMAT,
  TYPE_RELATIONS,
  TYPE_MEETING,
  TYPE_CALL,
  TYPE_LETTER,
  TYPE_KIND,
  TYPE_CONTACT_TO,
  TYPE_CONTACT,
  TYPE_LETTER_KIND,
  DATE_TIME_FORMAT_FM,
  TYPE_PROJECT,
  RESPONSIBLE,
  TYPE_AGREEMENT,
  VIDEOS,
  SCHEDULER,
  TYPE_ACQUAINTANCE,
  TYPE_TAGS,
  TYPE_CO_RESPONSIBLES,
  TYPE_APPROVING_MANAGER,
  TYPE_COMPLETE_REQUIRE,
  TYPE_AGREEMENT_TASK,
  TYPE_MEETING_CONTACTS
  // COMPLETE_CLOSE_ORDER,
  // TYPE_ORDER
  // STATUS_NEGOTIATION,
  // STATUS_ACTIVE
} from 'constants/index';

import LocationInput from 'components/common/controls/location-input';
import MarkdownFormItem from 'components/common/new-editor/form/markdown-form-item';
import DatePicker from 'components/common/date-picker';
import ActionsDropdown from 'components/common/actions';
import { convertMessageToString } from 'components/common/comments/converters';
import {
  ContactSelect,
  EmployeeSelect,
  // OrderSelect,
  ProjectSelect,
  SprintSelect,
  RelationsSelect
} from 'components/common/controls/custom-select';
import VideoBtn from 'components/common/video-btn';
import Button from 'components/common/button';
import Switch from 'components/common/controls/switch';
import ProjectParticipantsSelect from 'components/common/controls/custom-select/project-participants-select';
import Icon from 'components/common/icon';
import TagsSelect from 'components/common/controls/custom-select/tags-select';
import { LabelWithTooltip } from 'components/common/hook-form';
import useMeetingCalendarContext from 'components/tasks-view/components/meeting-calendar/use-meeting-calendar-context';
import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';
import useValidityDateFileList from 'components/common/validity-date/use-validity-date-file-list';
import { CloseIcon } from 'components/common/icon/icons';

import {
  getActiveWorkspace,
  getCompanyWorkspaces,
  getUserEmployee
} from 'store/workspace';

import useMinMaxTime from 'hooks/common/use-min-max-time';
import { mapUserToSelectValue } from 'utils/map-user-to-select';
import getTimeByEstimate from 'utils/get-time-by-estimate';
import useUploadingFiles from 'hooks/common/use-file-upload/use-uploading-files';
import { usePrevious } from 'hooks';
import alphabeticallySort from 'utils/alphabetically-sort';
import {
  validateMinLength,
  validateMaxLength,
  validateIsNumber,
  makeValidateDatesStartAfterEnd,
  validateIsRequire
} from 'utils/validators';
import { getFullName } from 'utils/get-fio';

import ActionTypeMembers from '../../components/actions/type-members';
import ActionTypeController from '../../components/actions/type-controller';
import ActionTypeResponsible from '../../components/actions/type-responsible';
import ActionTypeCoResponsibles from '../../components/actions/type-co-responsibles';
import LetterTypeSelect from './letter-type-select';
import IntervalSelect from './interval-select';
import AgreementSteps from './agreement-steps';
import TaskTypeSelect from '../../../../components/task-type-select';
import {
  getFieldsByTypes,
  getFullFilledFields,
  isAllowedToDelete
} from '../utils';
import CompleteRequireSelect from './complete-require-select';

import styles from './styles.module.scss';

const { Text } = Typography;

const videoSlugByType = {
  [TYPE_ACQUAINTANCE]: VIDEOS.cardTaskAcquaintance,
  [TYPE_AGREEMENT]: VIDEOS.cardTaskAgreement
};

const MainForm = Form.create({})(({
  form,
  onSubmit,
  current,
  setCurrent,
  currentTaskType,
  setCurrentTaskType,
  values = {},
  isLoading,
  isBacklog,
  isSprints,
  isFromTemplate,
  meetingCalendar
}) => {
  const prevValues = usePrevious(values);

  const workspaces = useSelector(getCompanyWorkspaces);
  const workspace = useSelector(getActiveWorkspace) || {};
  const user = useSelector(getUserEmployee);

  const [activeFields, setActiveFields] = useState([]);
  const [visibleDateStart, setVisibleDateStart] = useState(false);
  const [contactToVisibility, setContactToVisibility] = useState(false);

  const { goToDate, selectedDates, selectDatesToCalendar, setResources } =
    useMeetingCalendarContext();

  const { t } = useTranslation([
    'AddTask',
    'AddTaskType',
    'Errors',
    'TaskLinks',
    'Common'
  ]);

  const {
    getFieldDecorator,
    validateFields,
    getFieldValue,
    setFieldsValue,
    resetFields
  } = form;

  const isTopTask = !(values || {}).parent;
  const isSlaTask = !!Object.values(values.taskInfo || {}).length;

  const isTypeTask = (currentTaskType || '').includes(TYPE_TASK);
  const isTypeLetter = currentTaskType === TYPE_LETTER;
  const isTypeAgreement = currentTaskType === TYPE_AGREEMENT;
  const isTypeMeeting = currentTaskType === TYPE_MEETING;
  const isTypeCall = currentTaskType === TYPE_CALL;
  const isTypeAcquaintence = currentTaskType === TYPE_ACQUAINTANCE;

  const relations = getFieldValue('relations') || [];

  const descriptionTask =
    typeof values.description === 'string'
      ? values.description
      : (values.description || []).filter(el => !el.link);

  // const relatedOrder = relations.find(({ type }) => type === TYPE_ORDER);
  const relatedAsset = relations.find(({ type }) => type === TYPE_ASSET);

  const hideResponsibleField =
    isTypeAgreement || isTypeAcquaintence || isTypeMeeting;

  const initialTags = (values.tags || []).map(tag => ({
    value: tag.id,
    label: tag
  }));

  const project = getFieldValue('project');
  const projectId = (project || {}).value;

  const coResponsibles = getFieldValue(TYPE_CO_RESPONSIBLES);

  const Select = projectId ? ProjectParticipantsSelect : EmployeeSelect;

  const alertTextByType = {
    [TYPE_AGREEMENT]: t('IntroductionDocsWarning', {
      taskType: t('AgreementHeading', { ns: 'AddTaskType' })
    }),
    [TYPE_ACQUAINTANCE]: t('IntroductionDocsWarning', {
      taskType: t('IntroductionHeading', { ns: 'AddTaskType' })
    })
  };

  const onChangeTaskType = value => {
    setCurrentTaskType(value);
    meetingCalendar.setVisible(false);
  };

  const getInitialCoResponsibles = () => {
    if (isTypeMeeting && !isFromTemplate && !values.isCopy) {
      return [mapUserToSelectValue(user)];
    }

    if ((values.coResponsibles || []).length) {
      return values.coResponsibles.map(mapUserToSelectValue);
    }

    return [];
  };

  const selectRef = useRef(null);

  useEffect(() => {
    setVisibleDateStart(false);
    setContactToVisibility(false);

    switch (currentTaskType) {
      case TYPE_MEETING:
        setVisibleDateStart(true);
        break;
      case TYPE_CALL:
      case TYPE_LETTER:
        setContactToVisibility(true);
        break;
      default:
        break;
    }

    const fullFilledFields = getFullFilledFields({ values, activeFields });

    if (fullFilledFields.includes(TYPE_DATE_START)) setVisibleDateStart(true);

    setActiveFields(
      union(
        fullFilledFields,
        getFieldsByTypes(
          currentTaskType,
          fullFilledFields.sort(sortByCoResponsiblesAndController),
          isBacklog
        )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTaskType]);

  // When creating a task from outside, we update the form if there have been changes in initialData
  useEffect(() => {
    if (
      prevValues &&
      Object.keys(values || {}).length &&
      !isEqual(values, prevValues)
    ) {
      form.resetFields();
    }
  }, [form, prevValues, values]);

  useEffect(() => {
    if (relatedAsset) {
      setFieldsValue({ [SCHEDULER]: false });
    }
  }, [relatedAsset, setFieldsValue]);

  useEffect(() => {
    if (selectedDates.jsEvent) {
      setFieldsValue({
        [TYPE_DATE_START]: selectedDates.start,
        [TYPE_DATE_END]: selectedDates.end
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates]);

  useEffect(() => {
    if (values.dateStart && values.dateEnd) {
      selectDatesToCalendar({
        dateStart: values.dateStart,
        dateEnd: values.dateEnd
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.dateEnd, values.dateStart]);

  useEffect(() => {
    if (coResponsibles) {
      setResources(
        coResponsibles.map(r => ({
          id: r.value,
          title: getFullName(r.label)
        }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(coResponsibles || []).length]);

  const addField = type =>
    setActiveFields(
      [...activeFields, type].sort(sortByCoResponsiblesAndController)
    );

  const removeField = type => {
    if (type === TYPE_APPROVING_MANAGER) {
      setCurrentTaskType(TYPE_TASK);
    }
    setActiveFields(activeFields.filter(field => field !== type));
    setFieldsValue({ [type]: undefined }); // reset current value
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    const isValidValidityDates = validateValidityDateStateValues();

    if (!isValidValidityDates) {
      return;
    }

    validateFields(async (err, value) => {
      if (
        projectId &&
        !value.dateStart &&
        !value.dateEnd &&
        !getFieldValue(SCHEDULER) &&
        !activeFields.includes(TYPE_APPROVING_MANAGER)
      ) {
        // to reset field validation
        setFieldsValue({ [TYPE_DATE_END]: undefined });
      }

      const isErrSubTask = !!((value[DESCRIPTION] || {}).subTasks || []).find(
        subTask => !subTask.title
      );

      if (!err && !isErrSubTask) {
        setCurrent(current + 1);

        const getRelations = () => {
          let results = [];

          if (activeFields.includes(TYPE_RELATIONS)) {
            results = [...(value.relations || [])];
          }

          if (activeFields.includes(TYPE_MEETING_CONTACTS)) {
            results = [
              ...results,
              ...transformValueToRelation(
                value.meetingContacts || [],
                TYPE_CONTACT
              )
            ];
          }

          return results;
        };

        await onSubmit({
          ...value,
          hours: activeFields.includes(TYPE_TIME) ? value.hours : null,
          minutes: activeFields.includes(TYPE_TIME) ? value.minutes : null,
          approvingManager:
            currentTaskType === TYPE_AGREEMENT_TASK
              ? value.approvingManager
              : null,
          relations: getRelations(),
          project: isTopTask ? (value.project || {}).value || null : null,
          sprint: isTopTask ? (value.sprint || {}).value || null : null,
          storyPoint: +value.storyPoint || undefined,
          ...value[DESCRIPTION],
          [DESCRIPTION]: (value[DESCRIPTION] || {})[DESCRIPTION] && [
            {
              text: value[DESCRIPTION].description
            },
            ...(value[DESCRIPTION].links || [])
          ],
          ...(value[SCHEDULER]
            ? {
                schedulerConfig: {
                  freqInterval: value.freqInterval,
                  weekdays: value.weekdays,
                  freqType: value.freqType
                }
              }
            : {}),
          initialTags
        });

        resetFields();
      }
    });
  };

  const isPeriodic = getFieldValue(SCHEDULER);
  // const isCompleteCloseOrder =
  //   getFieldValue(TYPE_COMPLETE_REQUIRE) === COMPLETE_CLOSE_ORDER;

  useEffect(() => {
    setFieldsValue({
      saveAsTemplate: isPeriodic,
      updateTemplate: isPeriodic
    });
  }, [isPeriodic, setFieldsValue]);

  const startDate = getFieldValue(TYPE_DATE_START);
  const endDate = getFieldValue(TYPE_DATE_END);

  const formatDateValue = DATE_PICKER_TIME_FORMAT;

  const [minTimeStart, maxTimeStart, minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate,
    endDate
  });

  const showBacklogAlert =
    !getFieldValue(SCHEDULER) &&
    !activeFields.includes(TYPE_DATE_START) &&
    (!!projectId || isBacklog) &&
    !endDate;

  const changeDateEnd = date => {
    const result =
      !endDate && !moment(date).toDate().getHours()
        ? new Date(moment(date).toDate().setHours(23, 45))
        : date;

    if (!startDate) {
      goToDate(result);
    }

    selectDatesToCalendar({ dateStart: startDate, dateEnd: result });

    return result;
  };

  const isUploadingFiles = useUploadingFiles(
    (getFieldValue(DESCRIPTION) || {}).fileList
  );

  const apartValues = values && getTimeByEstimate(values.estimation);

  const isStoryPointEstimation = getFieldValue('isStoryPointEstimation');

  const { validateValidityDateStateValues, changeValidityDateStateValue } =
    useValidityDateFileList({
      fileList: (getFieldValue(DESCRIPTION) || {}).fileList,
      onChange: updatedFileList =>
        setFieldsValue({
          [DESCRIPTION]: {
            ...getFieldValue(DESCRIPTION),
            fileList: updatedFileList
          }
        })
    });

  const actions = [
    {
      type: TYPE_DATE_START,
      title: t(isTypeMeeting ? 'StartDateMeet' : 'StartDate'),
      onClick: () => {
        addField(TYPE_DATE_START);
        setVisibleDateStart(true);
      },
      icon: 'calendar',
      disabled: false,
      allow: true,
      visibility: true
    },
    {
      type: TYPE_TAGS,
      title: t('Tags'),
      onClick: () => addField(TYPE_TAGS),
      icon: 'tags',
      allow: true,
      visibility: true,
      component: (
        <Form.Item label={t('Tags')}>
          {getFieldDecorator(TYPE_TAGS, {
            initialValue: initialTags
          })(<TagsSelect />)}
        </Form.Item>
      )
    },
    {
      type: TYPE_ADDRESS,
      title: t('AddressHeading'),
      onClick: () => addField(TYPE_ADDRESS),
      icon: 'environment',
      allow: true,
      visibility: isTypeTask || isTypeMeeting,
      component: (
        <div className={styles.flexWrap} data-qa="qa-3egzsuhauo6xtmv">
          <Form.Item
            label={t('MainAddress')}
            className={styles.location}
            data-qa="qa-7y5as2qhq6pcy6a"
          >
            {getFieldDecorator('location', {
              initialValue: values.location || ''
            })(<LocationInput />)}
          </Form.Item>

          <Form.Item
            label={t('AdditionalAddress')}
            data-qa="qa-kmphokgzjucmwcj"
          >
            {getFieldDecorator('locationExact', {
              initialValue: values.locationExact || ''
            })(
              <Input
                autoComplete="off"
                allowClear={false}
                maxLength={25}
                data-qa="qa-12jnhs75vabmxwz"
              />
            )}
          </Form.Item>
        </div>
      )
    },
    {
      type: TYPE_CONTACT_TO,
      title: t('WhoToCall'),
      allow: true,
      onClick: () => {
        addField(TYPE_CONTACT_TO);
        setContactToVisibility(true);
      },
      icon: 'contacts',
      visibility: isTypeCall || isTypeLetter
    },
    {
      type: TYPE_MEMBERS,
      title: t('Coexecutors'),
      onClick: () => addField(TYPE_MEMBERS),
      icon: 'big-team',
      allow: true,
      visibility: true,
      component: (
        <div className={styles.members} data-qa="qa-ssr3ysl0qog09qb">
          <ActionTypeMembers
            getFieldDecorator={getFieldDecorator}
            projectId={projectId}
            values={values}
            data-qa="qa-8rjy32umau0vo0s"
            currentTaskType={currentTaskType}
          />
        </div>
      )
    },
    {
      type: TYPE_CO_RESPONSIBLES,
      allow: true,
      visibility: isTypeAcquaintence || isTypeMeeting,
      component: (
        <ActionTypeCoResponsibles
          initialValue={getInitialCoResponsibles()}
          projectId={projectId}
          getFieldDecorator={getFieldDecorator}
          label={t(isTypeAcquaintence ? 'Acquainted' : 'Employees')}
        />
      )
    },
    {
      type: TYPE_MEETING_CONTACTS,
      allow: true,
      visibility: isTypeMeeting,
      component: (
        <>
          <Form.Item label={t('ContactsOptional')}>
            {getFieldDecorator(TYPE_MEETING_CONTACTS, {
              initialValue: (values.relations || [])
                .filter(item => item.type === TYPE_CONTACT)
                .map(item => ({
                  label: {
                    ...item.relation,
                    firstName: item.relation.name
                  },
                  value: item.objectId
                }))
            })(
              <ContactSelect label={null} isMulti closeMenuOnSelect={false} />
            )}
          </Form.Item>

          <Alert type="warning" message={t('MembersWarning')} />
        </>
      )
    },
    {
      type: TYPE_CONTROLLER,
      title: t('Controller'),
      allow: true,
      onClick: () => addField(TYPE_CONTROLLER),
      icon: 'user-add',
      visibility: !isTypeAgreement && !isTypeAcquaintence && !isTypeMeeting,
      component: (
        <ActionTypeController
          getFieldDecorator={getFieldDecorator}
          projectId={projectId}
          initialValue={mapUserToSelectValue(values.controller)}
        />
      )
    },
    {
      type: TYPE_APPROVING_MANAGER,
      title: t('Approving'),
      allow: true,
      onClick: () => {
        addField(TYPE_APPROVING_MANAGER);
        setCurrentTaskType(TYPE_AGREEMENT_TASK);
      },
      icon: 'audit',
      visibility: isTypeTask,
      component: (
        <Form.Item label={t('Approving')}>
          {getFieldDecorator(TYPE_APPROVING_MANAGER, {
            initialValue: (values || {}).approvingManager && {
              value: values.approvingManager.id,
              label: values.approvingManager
            },
            rules: [
              {
                required: activeFields.includes(TYPE_APPROVING_MANAGER),
                message: t('RequiredField', { ns: 'Errors' })
              }
            ]
          })(<Select projectId={projectId} data-qa="qa-ne5fq02z4q1i2bd" />)}
        </Form.Item>
      )
    },
    {
      type: TYPE_TIME,
      title: t('EstimatedTime'),
      onClick: () => addField(TYPE_TIME),
      icon: 'timer',
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: -10
      },
      component: (
        <>
          <Text
            className={classnames(styles.subTitle, styles.block)}
            data-qa="qa-5d3rzy2j6qplqgy"
          >
            {t('EstimatedTime')}
          </Text>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
          >
            {isStoryPointEstimation ? (
              <Form.Item label={t('Storypoints')}>
                {getFieldDecorator('storyPoint', {
                  initialValue: values.storyPoint,
                  rules: [validateIsNumber()]
                })(<Input size="default" placeholder="000" />)}
              </Form.Item>
            ) : (
              <div className={styles.timeset} data-qa="qa-pz7tabse7kerbw0">
                <Form.Item label={t('Hours')}>
                  {getFieldDecorator('hours', {
                    rules: [validateIsNumber(), validateMaxLength(3)],
                    initialValue: apartValues.hours
                      ? apartValues.hours.toString()
                      : null
                  })(
                    <Input
                      size="default"
                      placeholder="000"
                      data-qa="qa-l2xkyoh6t3se8nj"
                    />
                  )}
                </Form.Item>
                <Form.Item label={t('Minutes')}>
                  {getFieldDecorator('minutes', {
                    rules: [validateIsNumber(), validateMaxLength(3)],
                    initialValue: apartValues.minutes
                      ? apartValues.minutes.toString()
                      : null
                  })(
                    <Input
                      size="default"
                      placeholder="000"
                      data-qa="qa-lc9tek4yde3n8w6"
                    />
                  )}
                </Form.Item>
              </div>
            )}

            {!values.parent && (
              <Form.Item style={{ margin: '27px 0 0 0', width: 'auto' }}>
                {getFieldDecorator('isStoryPointEstimation', {
                  initialValue: values.isStoryPointEstimation
                })(<Switch label={t('ChangeToStorypointsToggle')} />)}
              </Form.Item>
            )}
          </div>
        </>
      )
    },
    {
      type: TYPE_RELATIONS,
      title: t('LinksHeading'),
      onClick: () => addField(TYPE_RELATIONS),
      icon: 'link',
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: -8
      },
      component: (
        <>
          <Text
            className={classnames(styles.subTitle, styles.block)}
            style={{ marginBottom: 16 }}
            data-qa="qa-tolj1gemcntd5ei"
          >
            {t('LinksHeading')}
          </Text>

          <Form.Item
            className={styles.relationsSelect}
            label={
              <LabelWithTooltip
                label={t('LinkType', { ns: 'TaskLinks' })}
                tooltip={t('TaskLinksDesc', { ns: 'TaskLinks' })}
              />
            }
          >
            {getFieldDecorator('relations', {
              initialValue: (values.relations || []).filter(r =>
                isTypeMeeting ? r.type !== TYPE_CONTACT : true
              )
            })(
              <RelationsSelect
                hiddenTypes={
                  ((isTypeCall || isTypeLetter || isTypeMeeting) && [
                    TYPE_CONTACT
                  ]) ||
                  // (isCompleteCloseOrder && [TYPE_ORDER]) ||
                  []
                }
              />
            )}
          </Form.Item>
        </>
      )
    },
    {
      type: TYPE_PROJECT,
      title: t('Project'),
      onClick: () => addField(TYPE_PROJECT),
      icon: 'project',
      allow: true,
      visibility: isTopTask,
      deleteBtnStyle: {
        top: -12
      },
      component: (
        <>
          <Text className={classnames(styles.subTitle, styles.block)}>
            {t('Project')}
          </Text>

          <div className={styles.projectFields}>
            <Form.Item>
              {getFieldDecorator('project', {
                initialValue: values.project && {
                  value: values.project.id,
                  label: values.project
                }
              })(
                <ProjectSelect
                  label={t('Project')}
                  params={{
                    isActive: true
                  }}
                  callback={() => setFieldsValue({ sprint: null })}
                />
              )}
            </Form.Item>

            {project && !!project.label.sprintsCount && isTypeTask && (
              <Form.Item>
                {getFieldDecorator('sprint', {
                  initialValue: values.sprint && {
                    value: values.sprint.id,
                    label: values.sprint
                  }
                })(
                  <SprintSelect
                    label={t('Sprint')}
                    params={{
                      project: projectId,
                      status: ['active', 'planned'],
                      isLag: false
                    }}
                  />
                )}
              </Form.Item>
            )}
          </div>
        </>
      )
    },
    {
      type: TYPE_COMPLETE_REQUIRE,
      title: t('TaskResult'),
      onClick: () => addField(TYPE_COMPLETE_REQUIRE),
      icon: 'diff',
      allow: true,
      visibility: isTypeTask || isTypeCall || isTypeLetter,
      component: (
        <>
          <Form.Item label={t('TaskResultHeading')}>
            {getFieldDecorator(TYPE_COMPLETE_REQUIRE, {
              initialValue: values.completeRequire
            })(<CompleteRequireSelect />)}
          </Form.Item>

          {/* {isCompleteCloseOrder && (
              <Form.Item>
                {getFieldDecorator('closeOrder', {
                  initialValues: relatedOrder && {
                    value: relatedOrder.id,
                    label: {
                      statusId: relatedOrder.id,
                      ...relatedOrder,
                      ...relatedOrder.order
                    }
                  },
                  rules: [
                    {
                      required: isCompleteCloseOrder,
                      message: 'Required'
                    }
                  ]
                })(
                  <OrderSelect
                    fetchParams={{
                      isCustomer: false,
                      access: 'full',
                      status: [STATUS_NEGOTIATION, STATUS_ACTIVE]
                    }}
                    onChange={order => {
                      saveRelations([
                        ...relations.filter(rel => rel.type !== TYPE_ORDER),
                        order
                          ? {
                              id: order.value,
                              type: TYPE_ORDER,
                              ...order
                            }
                          : {}
                      ]);
                    }}
                  />
                )}
              </Form.Item>
            )} */}
        </>
      )
    }
  ].sort((a, b) => alphabeticallySort(a, b, 'title'));

  const getDateEndRules = () => {
    if (
      (projectId || isBacklog) &&
      !activeFields.includes(TYPE_APPROVING_MANAGER) &&
      !isPeriodic
    ) {
      return [];
    }

    return [
      validateIsRequire(),
      {
        validator: makeValidateDatesStartAfterEnd({
          form,
          checkSameDates: true
        })
      }
    ];
  };

  const renderTaskCreatedAlert = () =>
    workspaces.length > 1 && (
      <Alert
        type="warning"
        message={
          <Trans
            i18nKey="WorkspaceTaskWarning"
            ns="AddTask"
            components={{
              bold: <Text strong />
            }}
            values={{
              workspaceTitle: workspace.title
            }}
          />
        }
        style={{ fontSize: 12, marginBottom: 15 }}
      />
    );

  const renderTitle = () => (
    <div className={styles.field} data-qa="qa-bjc7ya7fwudhyna">
      <Form.Item label={t('TaskName')}>
        {getFieldDecorator(TITLE, {
          initialValue: values.title,
          rules: [validateIsRequire(), validateMinLength(2)]
        })(
          <Input
            className={styles.title}
            autoFocus
            autoComplete="off"
            data-qa="qa-"
          />
        )}
      </Form.Item>
    </div>
  );

  const renderDescription = () => (
    <>
      <div
        className={classnames(styles.field, styles.description)}
        data-qa="qa-kpga5cdf9m2eks8"
      >
        <MarkdownFormItem
          itemProps={{ label: t('TaskDescriptionMobile') }}
          markdownProps={{
            placeholder: t('TaskDescriptionPlchldr'),
            entityType: TYPE_TASK,
            resizeInput: true,
            allowManageSubscribers: true,
            hideSubscribersAction: true,
            hideValidityDateAction: true,
            validityDateDeps: {
              changeValidityDateStateValue
            },
            highlightAttachmentsBackground: true,
            mentionClassName: styles.mentionWrap
          }}
          getFieldDecorator={getFieldDecorator}
          initialValue={{
            description: convertMessageToString(descriptionTask),
            fileList: values.fileList || []
          }}
          name={DESCRIPTION}
        />
      </div>

      {!!alertTextByType[currentTaskType] && (
        <Alert
          type="warning"
          message={alertTextByType[currentTaskType]}
          style={{ marginBottom: 16 }}
        />
      )}
    </>
  );

  const renderResponsible = useCallback(() => {
    const responsibles = getFieldValue(RESPONSIBLE);
    const initialValue =
      isTypeCall || isTypeLetter ? [workspace.user] : values.responsible || [];

    return (
      <>
        <ActionTypeResponsible
          initialValue={initialValue.map(mapUserToSelectValue)}
          projectId={projectId}
          getFieldDecorator={getFieldDecorator}
          isMulti
        />

        {(responsibles || []).length > 1 && (
          <Alert
            type="warning"
            message={t('SeveralResponsiblesWarning')}
            style={{ fontSize: 12, marginBottom: 15 }}
          />
        )}
      </>
    );
  }, [
    getFieldDecorator,
    getFieldValue,
    isTypeCall,
    isTypeLetter,
    projectId,
    t,
    values.responsible,
    workspace.user
  ]);

  const getInitialDateStartValue = () => {
    if (isTypeMeeting) {
      return values.dateStart;
    }

    return values.dateStart
      ? moment(values.dateStart, DATE_TIME_FORMAT_FM).toDate().getTime()
      : new Date(moment().toDate().getTime());
  };

  const renderDates = () => (
    <div className={classnames(styles.dates)} data-qa="qa-i2p5c3lsddlcdgk">
      <div className={styles.dateContainer} data-qa="qa-n862olj2yefwsuv">
        {visibleDateStart && (
          <div className={styles.dateStart} data-qa="qa-el2pguhp7736pmj">
            <Form.Item label={t(isTypeMeeting ? 'StartDateMeet' : 'StartDate')}>
              {getFieldDecorator(TYPE_DATE_START, {
                valuePropName: 'selected',
                initialValue: getInitialDateStartValue(),
                rules: [
                  {
                    required: projectId || isTypeMeeting || isPeriodic,
                    message: t('RequiredField', { ns: 'Errors' })
                  }
                ],
                getValueFromEvent: date =>
                  date ? new Date(moment(date).toDate().getTime()) : date
              })(
                <DatePicker
                  showTimeSelect
                  timeIntervals={15}
                  wrapperClassname={styles.datePicker}
                  dateFormat={formatDateValue}
                  minDate={new Date()}
                  maxDate={endDate}
                  minTime={minTimeStart}
                  maxTime={maxTimeStart}
                  callback={date => {
                    selectDatesToCalendar({
                      dateStart: date,
                      dateEnd: endDate
                    });
                    goToDate(date);
                  }}
                />
              )}
            </Form.Item>

            {isAllowedToDelete(TYPE_DATE_START, currentTaskType) &&
              !isPeriodic && (
                <Button
                  icon={<CloseIcon />}
                  type="text"
                  className={styles.btnDelete}
                  data-qa="qa-je9cb5tlkwj268k"
                  onClick={() => {
                    removeField(TYPE_DATE_START);
                    setVisibleDateStart(false);
                  }}
                />
              )}
          </div>
        )}

        <Form.Item
          label={t(isTypeMeeting ? 'DueDateMeet' : 'TaskDueDate')}
          style={{ maxWidth: 180 }}
          data-qa="qa-xkk8v0ks2p0m0mt"
        >
          {getFieldDecorator(TYPE_DATE_END, {
            initialValue: values.dateEnd
              ? moment(values.dateEnd).toDate()
              : values.maxDateEnd && moment(values.maxDateEnd).toDate(),
            valuePropName: 'selected',
            getValueFromEvent: changeDateEnd,
            rules: getDateEndRules()
          })(
            <DatePicker
              showTimeSelect
              timeIntervals={15}
              wrapperClassname={styles.datePicker}
              dateFormat={formatDateValue}
              minDate={startDate || new Date()}
              maxDate={values.maxDateEnd && moment(values.maxDateEnd).toDate()}
              disabled={isSlaTask}
              minTime={minTimeEnd}
              maxTime={maxTimeEnd}
              onChange={changeDateEnd}
            />
          )}
        </Form.Item>

        {!isTypeAgreement &&
          getFieldDecorator(SCHEDULER, {
            initialValue: (values.schedulerConfig || {}).isActive || false,
            valuePropName: 'checked'
          })(
            <Checkbox
              style={{ marginLeft: 'auto', marginTop: 28 }}
              data-qa="qa-3tq9cu8wjxwuxol"
              disabled={relatedAsset || isSlaTask}
              onChange={() => {
                addField(TYPE_DATE_START);
                setVisibleDateStart(true);
              }}
            >
              {t('RepeatTaskChckbx')}
            </Checkbox>
          )}
      </div>

      {isSlaTask && (
        <Alert
          type="warning"
          message={t('SLADateWarning')}
          className={styles.slaAlert}
        />
      )}

      {showBacklogAlert && (
        <Alert
          type="warning"
          message={t('BacklogWarning')}
          style={{ fontSize: 12, marginBottom: 8 }}
        />
      )}

      {isPeriodic && (
        <IntervalSelect
          form={form}
          values={values}
          data-qa="qa-8t8ukl392wzeokt"
        />
      )}
    </div>
  );

  const renderAgreementSteps = () => {
    if (isTypeAgreement) {
      return (
        <AgreementSteps
          getFieldDecorator={getFieldDecorator}
          initialValue={values.agreementSteps}
          data-qa="qa-nydgxfvehw9xibe"
        />
      );
    }

    return null;
  };

  const filteredActions = actions
    .filter(a => !activeFields.includes(a.type))
    .filter(action => action.visibility);

  const sortByCoResponsiblesAndController = (a, b) => {
    if (a === TYPE_CONTROLLER || a === TYPE_CO_RESPONSIBLES) {
      return -1;
    }
    if (b === TYPE_CONTROLLER || b === TYPE_CO_RESPONSIBLES) {
      return 1;
    }
    return 0;
  };

  return (
    <Form
      className={styles.root}
      data-qa="qa-ubmmun9vwsxz5du"
      onSubmit={handleSubmit}
      hideRequiredMark
      colon={false}
    >
      <div className={styles.typeTaskSelect} data-qa="qa-x9zm1bibr51m508">
        <Text className={styles.subTitle} data-qa="qa-70ihlphbudp36lp">
          {t('TaskTypeHeading')}
        </Text>
        <Form.Item className={styles.select} data-qa="qa-y604ax0rpr13ui4">
          {getFieldDecorator(TYPE_KIND, {
            initialValue:
              currentTaskType === TYPE_AGREEMENT_TASK
                ? TYPE_TASK
                : currentTaskType
          })(
            <TaskTypeSelect
              onChange={onChangeTaskType}
              data-qa="qa-95kighxbsvlzir4"
              ref={selectRef}
              onlyAllowedTypes
              disabled={
                isBacklog ||
                isSprints ||
                values.isDisabledTypeSelect ||
                relatedAsset ||
                isSlaTask
              }
            />
          )}
        </Form.Item>
      </div>

      <div className={styles.fieldsWrap} data-qa="qa-qekax3a8aav153d">
        <VideoBtn
          slug={videoSlugByType[currentTaskType] || VIDEOS.cardTask}
          style={{ marginBottom: 10 }}
        />

        {renderTaskCreatedAlert()}

        {renderTitle()}

        {renderDescription()}

        {renderDates()}

        {isTypeMeeting && (
          <Button
            type="secondary"
            size="large"
            className={styles.visibleCalendarBtn}
            onClick={() => meetingCalendar.setVisible(!meetingCalendar.visible)}
          >
            {t(meetingCalendar.visible ? 'HideCalendarBtn' : 'OpenCalendarBtn')}
          </Button>
        )}

        {!hideResponsibleField && renderResponsible()}

        {renderAgreementSteps()}

        {(activeFields || []).map(field => {
          const action = actions
            .filter(item => item.visibility)
            .find(item => item.type === field);

          if (field !== TYPE_DATE_START) {
            return (
              <div
                key={field}
                className={styles.field}
                data-qa="qa-h5ztwdjpszrlnx0"
              >
                {action && action.component}

                {isAllowedToDelete(field, currentTaskType) && (
                  <Button
                    type="text"
                    icon={<CloseIcon />}
                    className={styles.btnDelete}
                    style={(action || {}).deleteBtnStyle}
                    data-qa="qa-yau3zi7shuxwodz"
                    onClick={() => removeField(field)}
                  />
                )}
              </div>
            );
          }

          return undefined;
        })}

        {contactToVisibility && (
          <div className={styles.withHint} data-qa="qa-zfwkursejfcgrsi">
            <Form.Item label={t(isTypeCall ? 'WhoToCall' : 'WhoToSend')}>
              {getFieldDecorator(TYPE_CONTACT_TO, {
                initialValue: (values.relations || [])
                  .filter(item => item.type === TYPE_CONTACT)
                  .map(item => ({
                    label: {
                      ...item.label,
                      firstName: item.label.name
                    },
                    value: item.objectId
                  })),
                rules: [
                  {
                    required: contactToVisibility,
                    message: t('RequiredField', { ns: 'Errors' })
                  }
                ]
              })(
                <ContactSelect label={null} isMulti closeMenuOnSelect={false} />
              )}
            </Form.Item>

            <Alert
              type="warning"
              message={t('WhoToSendWarning')}
              style={{ fontSize: 12 }}
            />

            {isTypeLetter && (
              <Form.Item
                label={t('WhatToSend')}
                className={styles.letterKind}
                data-qa="qa-rl65a8u5xtb8rh3"
              >
                {getFieldDecorator(TYPE_LETTER_KIND, {
                  initialValue: values.letterKind,
                  rules: [
                    {
                      required: isTypeLetter,
                      message: t('RequiredField', { ns: 'Errors' })
                    }
                  ]
                })(<LetterTypeSelect isMulti data-qa="qa-qqv6obb1goky6oe" />)}
              </Form.Item>
            )}
          </div>
        )}
      </div>

      <Divider className={styles.divider} data-qa="qa-sb8qczoewi8j9t7" />

      <ActionsDropdown
        actions={filteredActions}
        placement="bottomLeft"
        btnClassName={styles.actionsWrap}
        data-qa="qa-ul9pdpfvyr1qaf2"
        overlayClassName={styles.dropdownOverlay}
      >
        <Button
          type="link"
          weight="bold"
          className={styles.btnDropdown}
          data-qa="qa-s6038pwmz8o0h26"
        >
          <Icon type="plus" /> {t('ShowAllFieldsBtn')}
        </Button>
      </ActionsDropdown>

      <div className={styles.footerActions} data-qa="qa-ia26ypbtpanka41">
        {isFromTemplate && !values.isScheduler ? (
          getFieldDecorator('updateTemplate', {
            initialValue: values.updateTemplate,
            valuePropName: 'checked'
          })(
            <Checkbox disabled={isPeriodic}>
              {t('UpdateTemplateChckbx')}
            </Checkbox>
          )
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {getFieldDecorator('saveAsTemplate', {
              initialValue: values.saveAsTemplate,
              valuePropName: 'checked'
            })(
              <Checkbox disabled={isPeriodic}>
                {t('SaveAsTemplateChckbx')}
              </Checkbox>
            )}

            <VideoBtn slug={VIDEOS.whatIsTemplate} style={{ marginTop: 5 }} />
          </div>
        )}

        <Button
          style={{ marginLeft: 'auto', minWidth: 130 }}
          htmlType="submit"
          type="primary"
          data-qa="qa-1ffnvx0iym4t05o"
          size="large"
          disabled={isLoading || isUploadingFiles}
          loading={isLoading || isUploadingFiles}
        >
          {t('CreateBtn')}{' '}
          {isUploadingFiles && ` ${t('FileLoading', { ns: 'Common' })}`}
        </Button>
      </div>
    </Form>
  );
});

MainForm.propTypes = {
  values: PropTypes.shape({
    isDisabledTypeSelect: PropTypes.bool,
    defaultRelationType: PropTypes.string
  }),
  current: PropTypes.number,
  setCurrent: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  relations: PropTypes.array.isRequired,
  saveRelations: PropTypes.func.isRequired,
  currentTaskType: PropTypes.string,
  setCurrentTaskType: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isBacklog: PropTypes.bool,
  isSprints: PropTypes.bool
};

MainForm.defaultProps = {
  values: {
    isDisabledTypeSelect: false,
    defaultRelationType: ''
  },
  current: 0,
  setCurrent: () => {},
  currentTaskType: TYPE_TASK,
  isLoading: false,
  isBacklog: false,
  isSprints: false
};

export default MainForm;
