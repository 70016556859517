import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { getRouterState } from '../../store/router';

export const useStateParam = (param, defaultValue = null) => {
  const state = useSelector(getRouterState, shallowEqual) || {};

  const [value, setValue] = useState(defaultValue || state[param]);

  useEffect(() => {
    setValue(state[param]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return value;
};

export default useStateParam;
