import React from 'react';
import { Menu, Divider } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './filter-menu.module.scss';

export const FilterMenu = ({ menu, className, ...props }) => (
  <Menu
    className={classnames(styles.root, className)}
    mode="horizontal"
    {...props}
  >
    {menu.map(({ type, ...item }) => {
      if (type === 'divider') {
        return (
          <Menu.Item
            key={item.key}
            label={item.label}
            onClick={item.onClick}
            className={classnames(styles.item, styles.divider)}
          >
            <Divider style={{ margin: 0, height: 26 }} type="vertical" />
          </Menu.Item>
        );
      }

      return (
        <Menu.Item
          key={item.key}
          label={item.label}
          onClick={item.onClick}
          className={styles.item}
        >
          {item.label}
        </Menu.Item>
      );
    })}
  </Menu>
);

FilterMenu.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.any,
      onClick: PropTypes.func
    })
  ).isRequired,
  className: PropTypes.string
};

FilterMenu.defaultProps = {
  className: undefined
};

export default FilterMenu;
