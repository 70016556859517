import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Tooltip, Tree, Spin, Progress, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  STATUS_DONE,
  TYPE_AGREEMENT,
  VIDEOS,
  TYPE_ACQUAINTANCE,
  TYPE_MEETING,
  STATUS_CANCELLED,
  STATUS_CHECK
} from 'constants/index';

import VideoBtn from 'components/common/video-btn';
import Icon from 'components/common/icon';

import { fetchSubtaskList } from 'store/tasks';

import useRoutesService from 'services/routes';
import { getIsTaskOutdated } from 'utils/get-is-outdated';

import CreateSubtaskModal from '../create-subtask-modal';
import SubtaskCard from '../subtask-card';
import CustomCollapse from '../collapse';

import styles from './subtask-list.module.scss';

const SubtaskList = ({ task }) => {
  const routes = useRoutesService();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [visibleCreator, setVisibleCreator] = useState(false);

  const { t } = useTranslation('Task');

  const isStatusCheck = task.status === STATUS_CHECK;

  const fetchData = async () => {
    setIsLoading(true);

    try {
      await dispatch(fetchSubtaskList({ id: task.id }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.id, task.project, task.sprint]);

  const getCountByStatus = useCallback(
    status =>
      (task.children || []).filter(subtask => subtask.status === status).length,
    [task.children]
  );

  const getDonePercent = useCallback(() => {
    const totalCount = (task.children || []).length;
    const doneCount = getCountByStatus(STATUS_DONE);
    const cancelledCount = getCountByStatus(STATUS_CANCELLED);

    return Math.round((doneCount / (totalCount - cancelledCount)) * 100);
  }, [getCountByStatus, task.children]);

  const renderTitle = useCallback(
    () => (
      <div className={styles.title} data-qa="qa-csg1plobnfyhusg">
        <span data-qa="qa-b9d81ddsoopj21x">{t('SubtasksHeading')}</span>

        {!(task.children || []).length && (
          <VideoBtn slug={VIDEOS.createSubTask} />
        )}

        <Spin spinning={isLoading} size="small" />
      </div>
    ),
    [isLoading, t, task.children]
  );

  const renderStatistic = useCallback(
    () => (
      <div className={styles.statistic} data-qa="qa-3txluc5r9wp9i2k">
        <div className={styles.left} data-qa="qa-veiylyezt51sff9">
          <div>
            {t('SubtasksTotal')} <strong>{(task.children || []).length}</strong>
          </div>
          <div className={styles.text} data-qa="qa-0d1zg1z25kwkyr6">
            {t('SubtasksDeclined')} {getCountByStatus(STATUS_CANCELLED)}
          </div>
        </div>
        <div className={styles.right} data-qa="qa-y24c6zdt9e92q8p">
          <Progress
            strokeLinecap="square"
            strokeWidth={5}
            percent={getDonePercent()}
            style={{ lineHeight: 1.2 }}
            data-qa="qa-6kw5nwuu1iufivd"
          />
          <span className={styles.text} data-qa="qa-co1b8ik5h6rindo">
            {t('SubtasksDone')} {getCountByStatus(STATUS_DONE)}
          </span>
        </div>
      </div>
    ),
    [getCountByStatus, getDonePercent, t, task.children]
  );

  const getTransformTask = useCallback(
    subtask => ({
      title: (
        <SubtaskCard
          {...subtask}
          className={styles.option}
          data-qa="qa-u2jjqqi1qwpgdsa"
        />
      ),
      key: subtask.id,
      children: (subtask.subTasks || []).map(getTransformTask)
    }),
    []
  );

  const hasSubtaskList = !!(task.children || []).length;
  const treeData = (task.children || []).map(getTransformTask);

  const isTypeAgreement = task.kind === TYPE_AGREEMENT;
  const isTypeAcquaintence = task.kind === TYPE_ACQUAINTANCE;
  const isTypeMeeting = task.kind === TYPE_MEETING;

  const { createSubTask } = task.permissions;
  const allowCreateSubTask =
    createSubTask && !isTypeAgreement && !isTypeAcquaintence && !isTypeMeeting;

  const isOutdated = getIsTaskOutdated(task);

  const getTooltipTitle = useCallback(() => {
    if (isOutdated) {
      return t('OverdueSubtasksTip');
    }

    if (isStatusCheck) {
      return t('ClosedSubtasksTip');
    }

    return t('AddSubtaskBtn');
  }, [isOutdated, isStatusCheck, t]);

  return (
    <div className={styles.root} data-qa="qa-w3cdhrqerodsilt">
      {allowCreateSubTask && (
        <Tooltip title={getTooltipTitle()} placement="topRight">
          <Button
            type="link"
            onClick={() => setVisibleCreator(true)}
            className={styles.btn}
            data-qa="qa-rsho8nnb4fivhz8"
            disabled={isOutdated || isStatusCheck}
          >
            <Icon type="plus" color="black-55" data-qa="qa-2rugt0vbszunkcs" />
          </Button>
        </Tooltip>
      )}

      {hasSubtaskList ? (
        <CustomCollapse defaultActiveKey={['subtasks']}>
          <CustomCollapse.Panel
            header={renderTitle()}
            key="subtasks"
            showArrow={(task.children || []).length > 0}
          >
            {renderStatistic()}

            <Tree
              treeData={treeData}
              selectable={false}
              rootClassName={styles.treeSubTasks}
            />

            <Button
              type="link"
              data-qa="qa-y3kwv7uv6m0omze"
              style={{ paddingLeft: 0 }}
              onClick={() => routes.toHierarchy(task.id)}
            >
              {t('OpenTaskTreeBtn')}
            </Button>
          </CustomCollapse.Panel>
        </CustomCollapse>
      ) : (
        <>
          {renderTitle()}
          {!!task.parent && (
            <Button
              type="link"
              data-qa="qa-zpvxc8u0eq7pgvf"
              style={{ paddingLeft: 0 }}
              onClick={() => routes.toHierarchy(task.id)}
            >
              {t('OpenTaskTreeBtn')}
            </Button>
          )}
        </>
      )}

      <Divider className={styles.divider} />

      <CreateSubtaskModal
        visible={visibleCreator}
        task={task}
        onClose={() => setVisibleCreator(false)}
      />
    </div>
  );
};

SubtaskList.propTypes = {
  task: PropTypes.object.isRequired
};

export default SubtaskList;
