import { createSelector } from 'reselect';
import { memoize } from 'lodash';

import { ACTIVE_IDEA } from '../../../constants';

export const getIdeas = state => state.ideas.activeIdeas;

export const getFilterIdeas = createSelector(getIdeas, ideas => ideas.filter);

export const getFilterSearchIdeas = createSelector(
  getFilterIdeas,
  filter => filter.search
);

export const getEntriesIdeas = createSelector(getIdeas, ideas => ideas.entries);

export const getIdea = createSelector(getEntriesIdeas, entries =>
  memoize(id => {
    const idea = entries.find(item => item.id === id);
    return idea && { ...idea, type: ACTIVE_IDEA };
  })
);

export const getIsIdeasLoaded = createSelector(
  getIdeas,
  ideas => ideas.isLoaded
);

export const getIsIdeasLoading = createSelector(
  getIdeas,
  ideas => ideas.isLoading
);

export const getHasErrorIdeas = createSelector(
  getIdeas,
  ideas => !!ideas.error
);

export const getHasMoreIdeas = createSelector(getIdeas, ideas => ideas.hasMore);

export const getItemsPerPage = createSelector(
  getIdeas,
  ideas => ideas.itemsPerPage
);

export const getAllEntriesLength = createSelector(
  getEntriesIdeas,
  entries => entries.length
);
