import React, { useState, useEffect } from 'react';
import { Typography, Button } from 'antd';
import { Form } from '@ant-design/compatible';
import classnames from 'classnames';
import { isEqual, uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import { EmployeeSelect } from 'components/common/controls/custom-select';

import { validateIsRequire } from 'utils/validators';
import { usePrevious } from 'hooks';

import styles from './agreement-steps.module.scss';

const { Title, Paragraph } = Typography;

const getNewStep = () => ({ id: uniqueId(), employees: [], isEdit: true });

export const AgreementSteps = ({
  getFieldDecorator,
  setFieldsValue,
  initialValue
}) => {
  const prevInitialValue = usePrevious(initialValue) || [];
  const [list, setList] = useState([getNewStep()]);

  const { t } = useTranslation('AddTask');

  const onAddStep = () => setList(prevList => [...prevList, getNewStep()]);
  const onDeleteStep = index => {
    const filtredSteps = list.filter((_, i) => i !== index);
    setList(filtredSteps);
    // при обычном вызове setFieldsValue функция не срабатывает,
    // так как пытается обновить сама себя
    setTimeout(() => {
      if (typeof setFieldsValue === 'function') {
        setFieldsValue({ agreementSteps: filtredSteps });
      }
    });
  };

  useEffect(() => {
    if (initialValue && !isEqual(initialValue, prevInitialValue)) {
      setList(
        (initialValue || []).map(step => ({
          id: uniqueId(),
          ...step
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <div className={styles.root} data-qa="qa-zcvchofb9s0ksq7">
      <Title level={4} style={{ fontSize: 16 }}>
        {t('AgreementProcedureHeading')}
      </Title>
      <Paragraph style={{ marginBottom: 10 }}>
        {t('AgreementProcedureDesc')}
      </Paragraph>

      <div className={styles.steps} data-qa="qa-wxwakqdgi344z31">
        {list.map((agreement, index) => (
          <div
            key={agreement.id}
            className={classnames(styles.step, {
              [styles.lastStep]: index === list.length - 1,
              [styles.disabled]: !agreement.isEdit
            })}
            data-qa="qa-6u8s07rzgkw2y5d"
          >
            <Paragraph
              style={{ marginBottom: 8 }}
              className={styles.flexWrap}
              data-qa="qa-arz6yiz3vxwjh3y"
            >
              <span>
                {t('Step')} {index + 1}
              </span>
              {list.length > 1 && (
                <Button
                  type="link"
                  size="small"
                  onClick={() => onDeleteStep(index)}
                  className={styles.closeBtn}
                  data-qa="qa-rkaccm9wg50gn06"
                >
                  <Icon type="close" />
                </Button>
              )}
            </Paragraph>
            <Form.Item
              label={t(index === 0 ? 'Coordinators' : 'NextCoordinators')}
            >
              {getFieldDecorator(`agreementSteps[${agreement.id}].employees`, {
                initialValue: list[index].employees || [],
                rules: [validateIsRequire()]
              })(
                <EmployeeSelect
                  data-qa="qa-19f5v91pwhzog0n"
                  isMulti
                  allowSelectAll
                  showSelectedOptionsOnList={false}
                />
              )}
            </Form.Item>
          </div>
        ))}

        <Button
          type="link"
          style={{ paddingLeft: 0 }}
          onClick={onAddStep}
          data-qa="qa-qyfdf32ny6xnzud"
        >
          {t('AddStepBtn')}
        </Button>
      </div>
    </div>
  );
};

export default AgreementSteps;
